import { graphql } from 'relay-runtime'
import { useFragment } from 'react-relay/hooks'
import type { PrefCityListCemeteries_prefectureCityCemeteries$key } from './__generated__/PrefCityListCemeteries_prefectureCityCemeteries.graphql'
import type { PrefCityListCemeteries_cemetery$key } from './__generated__/PrefCityListCemeteries_cemetery.graphql'
import { Cemeteries } from './Cemeteries'
import { useListCemeteries } from './dependencies'

type PrefCityListCemeteriesProps = {
  cemetery: PrefCityListCemeteries_cemetery$key
  prefectureCityCemeteries: PrefCityListCemeteries_prefectureCityCemeteries$key
}

export const PrefCityListCemeteries: React.FC<PrefCityListCemeteriesProps> = ({
  cemetery,
  prefectureCityCemeteries
}) => {
  const {
    cemeteryId,
    city: { name: cityName }
  } = useFragment(cemeteryFragment, cemetery)

  const { items } = useFragment(fragment, prefectureCityCemeteries)
  const cemeteryItems = useListCemeteries({ cemeteryId, items })

  if (cemeteryItems.length < 1) return null

  return (
    <Cemeteries
      eventLabel="cemeteryCard_市区町村で人気の霊園"
      title={`${cityName}で人気の霊園`}
      cemeteryItems={cemeteryItems}
    />
  )
}

const fragment = graphql`
  fragment PrefCityListCemeteries_prefectureCityCemeteries on CemeteryResult {
    items {
      cemeteryId: id
      ...useCemeteryTinyItem_cemetery
    }
  }
`

const cemeteryFragment = graphql`
  fragment PrefCityListCemeteries_cemetery on Cemetery {
    cemeteryId: id
    city {
      name
    }
  }
`
