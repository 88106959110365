/**
 * @generated SignedSource<<e36ef022027acc53b8fd7133fa6fca05>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime'
import { FragmentRefs } from 'relay-runtime'
export type PriorityCemeteries_cemetery$data = {
  readonly priority_cemeteries: ReadonlyArray<{
    readonly cemeteryId: number
    readonly ' $fragmentSpreads': FragmentRefs<'useCemeteryTinyItem_cemetery'>
  }>
  readonly ' $fragmentType': 'PriorityCemeteries_cemetery'
}
export type PriorityCemeteries_cemetery$key = {
  readonly ' $data'?: PriorityCemeteries_cemetery$data
  readonly ' $fragmentSpreads': FragmentRefs<'PriorityCemeteries_cemetery'>
}

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: null,
  name: 'PriorityCemeteries_cemetery',
  selections: [
    {
      alias: null,
      args: null,
      concreteType: 'CemeterySimple',
      kind: 'LinkedField',
      name: 'priority_cemeteries',
      plural: true,
      selections: [
        {
          alias: 'cemeteryId',
          args: null,
          kind: 'ScalarField',
          name: 'id',
          storageKey: null
        },
        {
          args: null,
          kind: 'FragmentSpread',
          name: 'useCemeteryTinyItem_cemetery'
        }
      ],
      storageKey: null
    }
  ],
  type: 'Cemetery',
  abstractKey: null
}

;(node as any).hash = '6e151b672a998c3a461ef484bcf27730'

export default node
