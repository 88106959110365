import { FC, useMemo } from 'react'
import { graphql } from 'relay-runtime'
import { useFragment } from 'react-relay/hooks'
import { LinksNearbyCity_nearbyCities$key } from '@/components/Links/__generated__/LinksNearbyCity_nearbyCities.graphql'
import { makeNearbyCityLinksData } from '@/components/Links/dependencies'
import { LinksBase } from '@/components/Links/LinksBase'
import { typography } from '@lifedot/styles/typography'
import { AddMarginWrapper } from '@lifedot/components/AddMarginWrapper'

interface LinksNearbyCityProps {
  nearbyCities: LinksNearbyCity_nearbyCities$key
}

export const LinksNearbyCity: FC<LinksNearbyCityProps> = ({ nearbyCities }) => {
  const nearbyCitiesData = useFragment(fragmentNearbyCities, nearbyCities)
  const links = useMemo(
    () => makeNearbyCityLinksData(nearbyCitiesData),
    [nearbyCitiesData]
  )

  if (links.length < 1) return null
  return (
    <AddMarginWrapper spacing={2}>
      <h2 css={typography.textL}>近隣市区町村から霊園・墓地を探す</h2>
      <LinksBase links={links} />
    </AddMarginWrapper>
  )
}

const fragmentNearbyCities = graphql`
  fragment LinksNearbyCity_nearbyCities on NearbyCityWithCemeteryCountConnection {
    items {
      prefectureRoma
      cityId: id
      name
      cemeteryCount
    }
  }
`
