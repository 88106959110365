/**
 * @generated SignedSource<<4479ea62d811da415c58e1295d489a88>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime'
import { FragmentRefs } from 'relay-runtime'
export type LinksStation_stations$data = {
  readonly prefecture: {
    readonly roma: string
  }
  readonly stations: ReadonlyArray<{
    readonly stationId: number
    readonly name: string
    readonly railway_line_name: string
    readonly distance: number
  }>
  readonly ' $fragmentType': 'LinksStation_stations'
}
export type LinksStation_stations$key = {
  readonly ' $data'?: LinksStation_stations$data
  readonly ' $fragmentSpreads': FragmentRefs<'LinksStation_stations'>
}

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: null,
  name: 'LinksStation_stations',
  selections: [
    {
      alias: null,
      args: null,
      concreteType: 'CemeteryPrefecture',
      kind: 'LinkedField',
      name: 'prefecture',
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'roma',
          storageKey: null
        }
      ],
      storageKey: null
    },
    {
      alias: null,
      args: null,
      concreteType: 'CemeteryStation',
      kind: 'LinkedField',
      name: 'stations',
      plural: true,
      selections: [
        {
          alias: 'stationId',
          args: null,
          kind: 'ScalarField',
          name: 'id',
          storageKey: null
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'name',
          storageKey: null
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'railway_line_name',
          storageKey: null
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'distance',
          storageKey: null
        }
      ],
      storageKey: null
    }
  ],
  type: 'Cemetery',
  abstractKey: null
}

;(node as any).hash = '59da6125202f9d1f98b66b861e1b794c'

export default node
