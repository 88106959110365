import { FC } from 'react'
import { css } from '@emotion/react'
import { LinkData } from './dependencies'
import { palette } from '@lifedot/styles/palette'
import { typography } from '@lifedot/styles/typography'

const styles = {
  list: css({
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-start'
  }),
  item: css({
    border: `1px solid ${palette.gray2}`,
    borderRadius: 4,
    padding: '4px 8px',
    backgroundColor: palette.white,
    ':not(:last-of-type)': {
      marginRight: 4,
      marginBottom: 4
    },
    a: {
      color: palette.main03,
      textDecoration: 'none'
    }
  })
}

interface LinksBaseProps {
  links: LinkData
}

export const LinksBase: FC<LinksBaseProps> = ({ links }) => {
  return (
    <ul css={styles.list}>
      {links.map(({ href, label }) => (
        <li key={href} css={[typography.textM, styles.item]}>
          <a href={href}>{label}</a>
        </li>
      ))}
    </ul>
  )
}
