import { graphql } from 'relay-runtime'
import { useFragment } from 'react-relay/hooks'
import { FC } from 'react'
import { ThirdPartyAdData_cemetery$key } from './__generated__/ThirdPartyAdData_cemetery.graphql'
import { markup } from '@lifedot/utils'
import { makeAdData } from '@/components/ThirdPartyAdData/dependencies'

interface ThirdPartyAdDataProps {
  cemetery: ThirdPartyAdData_cemetery$key
}

export const ThirdPartyAdData: FC<ThirdPartyAdDataProps> = ({ cemetery }) => {
  const data = useFragment(fragment, cemetery)

  return (
    <script
      type="application/json"
      id="adData"
      dangerouslySetInnerHTML={markup(JSON.stringify(makeAdData(data)))}
    />
  )
}

const fragment = graphql`
  fragment ThirdPartyAdData_cemetery on Cemetery {
    cemeteryId: id
    code
    name
    prefecture {
      roma
      name
    }
    city {
      name
    }
    main_image {
      alt
      path
    }
    section_types {
      name
      code
    }
    cemetery_types {
      name
      code
    }
    sects {
      name
      code
    }
    best_price_by_category {
      normal {
        price1
        price1_unit
        price2
        purchase_price
        purchase_price_unit
        calculated_purchase_price
        category_code
        purchase_price_option
        price3
        simulated_price3
        price4
        price4_name
      }
      nokotsu {
        price1
        price1_unit
        price2
        purchase_price
        purchase_price_unit
        calculated_purchase_price
        category_code
        purchase_price_option
        price3
        simulated_price3
        price4
        price4_name
      }
      jumoku {
        price1
        price1_unit
        price2
        purchase_price
        purchase_price_unit
        calculated_purchase_price
        category_code
        purchase_price_option
        price3
        simulated_price3
        price4
        price4_name
      }
      eitai_kuyo {
        price1
        price1_unit
        price2
        purchase_price
        purchase_price_unit
        calculated_purchase_price
        category_code
        purchase_price_option
        price3
        simulated_price3
        price4
        price4_name
      }
    }
  }
`
