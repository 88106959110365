/**
 * @generated SignedSource<<c935c756142e89168a9a927fb45e7ba7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime'
import { FragmentRefs } from 'relay-runtime'
export type Anchors_cemetery$data = {
  readonly cemeteryId: number
  readonly review_summary: {
    readonly count: number
  }
  readonly price_and_specs: ReadonlyArray<{
    readonly priceId: number
  }>
  readonly ' $fragmentType': 'Anchors_cemetery'
}
export type Anchors_cemetery$key = {
  readonly ' $data'?: Anchors_cemetery$data
  readonly ' $fragmentSpreads': FragmentRefs<'Anchors_cemetery'>
}

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: null,
  name: 'Anchors_cemetery',
  selections: [
    {
      alias: 'cemeteryId',
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null
    },
    {
      alias: null,
      args: null,
      concreteType: 'CemeteryReviewSummary',
      kind: 'LinkedField',
      name: 'review_summary',
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'count',
          storageKey: null
        }
      ],
      storageKey: null
    },
    {
      alias: null,
      args: null,
      concreteType: 'CemeteryPriceAndSpec',
      kind: 'LinkedField',
      name: 'price_and_specs',
      plural: true,
      selections: [
        {
          alias: 'priceId',
          args: null,
          kind: 'ScalarField',
          name: 'id',
          storageKey: null
        }
      ],
      storageKey: null
    }
  ],
  type: 'Cemetery',
  abstractKey: null
}

;(node as any).hash = '9458d017c25a3b6d71b2a78a09a26382'

export default node
