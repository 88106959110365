import { DetailedHTMLProps, FC, HTMLAttributes } from 'react'
import { css } from '@emotion/react'
import { palette } from '@lifedot/styles/palette'
import { mq } from '@lifedot/styles/mediaQuery'
import { typography } from '@lifedot/styles/typography'

const styles = {
  section: css({
    padding: '32px 40px',
    backgroundColor: palette.white,
    display: 'grid',
    gap: 20,
    gridTemplateColumns: '100%',
    [mq('sp')]: {
      padding: '12px 8px',
      gap: 8
    }
  })
}

interface SectionProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement> {
  title?: string
}

export const Section: FC<SectionProps> = ({ title, children, ...rest }) => {
  return (
    <section css={styles.section} {...rest}>
      {!!title && <h2 css={typography.headingS}>{title}</h2>}
      <div>{children}</div>
    </section>
  )
}
