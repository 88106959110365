import { FC } from 'react'
import { SingleColumn } from '@lifedot/layout'
import { Banners } from '@/components/Banners/Banners'
import { BreadcrumbDummy } from '../Breadcrumb/BreadcrumbDummy'
import { css } from '@emotion/react'
import ContentLoader from 'react-content-loader'
import { mq } from '@lifedot/styles/mediaQuery'
import { palette } from '@lifedot/styles/palette'

const styles = {
  wrap: css({
    marginTop: 4
  }),
  contentLoader: css({
    height: 600,
    width: '100%',
    [mq('sp')]: {
      height: 400
    }
  }),
  content: css({
    x: 0,
    y: 0,
    rx: 5,
    ry: 5,
    width: 860,
    height: 600,
    [mq('sp')]: {
      width: '100%',
      height: 400
    }
  }),
  banner: css({
    marginTop: 24
  })
}

export const DummyContent: FC = () => {
  return (
    <div css={styles.wrap}>
      <SingleColumn>
        <BreadcrumbDummy />
        <ContentLoader
          css={styles.contentLoader}
          backgroundColor={palette.gray2}
        >
          <rect css={styles.content} />
        </ContentLoader>
        <ContentLoader
          css={styles.contentLoader}
          backgroundColor={palette.gray2}
        >
          <rect css={styles.content} />
        </ContentLoader>
        <ContentLoader
          css={styles.contentLoader}
          backgroundColor={palette.gray2}
        >
          <rect css={styles.content} />
        </ContentLoader>
        <div css={styles.banner}>
          <Banners />
        </div>
      </SingleColumn>
    </div>
  )
}
