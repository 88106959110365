/**
 * @generated SignedSource<<89bd335716f34c612dc57a2e586f3e4b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime'
import { FragmentRefs } from 'relay-runtime'
export type PrefectureListCemeteries_prefectureCemeteries$data = {
  readonly items: ReadonlyArray<{
    readonly cemeteryId: number
    readonly ' $fragmentSpreads': FragmentRefs<'useCemeteryTinyItem_cemetery'>
  }>
  readonly ' $fragmentType': 'PrefectureListCemeteries_prefectureCemeteries'
}
export type PrefectureListCemeteries_prefectureCemeteries$key = {
  readonly ' $data'?: PrefectureListCemeteries_prefectureCemeteries$data
  readonly ' $fragmentSpreads': FragmentRefs<'PrefectureListCemeteries_prefectureCemeteries'>
}

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: null,
  name: 'PrefectureListCemeteries_prefectureCemeteries',
  selections: [
    {
      alias: null,
      args: null,
      concreteType: 'Cemetery',
      kind: 'LinkedField',
      name: 'items',
      plural: true,
      selections: [
        {
          alias: 'cemeteryId',
          args: null,
          kind: 'ScalarField',
          name: 'id',
          storageKey: null
        },
        {
          args: null,
          kind: 'FragmentSpread',
          name: 'useCemeteryTinyItem_cemetery'
        }
      ],
      storageKey: null
    }
  ],
  type: 'CemeteryResult',
  abstractKey: null
}

;(node as any).hash = 'dfe2f3bbf50961931ffbf248963d4d95'

export default node
