/**
 * @generated SignedSource<<9d0a64ebfebbc7313f408e614a00b80f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime'
import { FragmentRefs } from 'relay-runtime'
export type SiteDescription_cemetery$data = {
  readonly name: string
  readonly city: {
    readonly name: string
  }
  readonly prefecture: {
    readonly name: string
  }
  readonly ' $fragmentType': 'SiteDescription_cemetery'
}
export type SiteDescription_cemetery$key = {
  readonly ' $data'?: SiteDescription_cemetery$data
  readonly ' $fragmentSpreads': FragmentRefs<'SiteDescription_cemetery'>
}

const node: ReaderFragment = (function () {
  var v0 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'name',
      storageKey: null
    },
    v1 = [v0 /*: any*/]
  return {
    argumentDefinitions: [],
    kind: 'Fragment',
    metadata: null,
    name: 'SiteDescription_cemetery',
    selections: [
      v0 /*: any*/,
      {
        alias: null,
        args: null,
        concreteType: 'CemeteryCity',
        kind: 'LinkedField',
        name: 'city',
        plural: false,
        selections: v1 /*: any*/,
        storageKey: null
      },
      {
        alias: null,
        args: null,
        concreteType: 'CemeteryPrefecture',
        kind: 'LinkedField',
        name: 'prefecture',
        plural: false,
        selections: v1 /*: any*/,
        storageKey: null
      }
    ],
    type: 'Cemetery',
    abstractKey: null
  }
})()

;(node as any).hash = '298d8e3bfc695c34d18072d4395690e3'

export default node
