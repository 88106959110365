import { typography } from '@lifedot/styles/typography'
import { css } from '@emotion/react'
import { palette } from '@lifedot/styles/palette'
import { EventTracker } from '@lifedot/tracking'
import { CemeteryCard } from '@lifedot/components/CemeteryCard'
import { PrefCityListCemeteries_prefectureCityCemeteries$data } from '../__generated__/PrefCityListCemeteries_prefectureCityCemeteries.graphql'
import { PrefectureListCemeteries_prefectureCemeteries$data } from '../__generated__/PrefectureListCemeteries_prefectureCemeteries.graphql'
import { PriorityCemeteries_cemetery$data } from '../__generated__/PriorityCemeteries_cemetery.graphql'
import { elementCategories } from '@lifedot/constants/elementCategories'

const styles = {
  cemeteries: css({
    display: 'flex',
    overflow: 'scroll',
    overflowX: 'auto',
    overflowY: 'hidden',
    padding: '0 4px 8px',
    marginTop: 8,
    '::-webkit-scrollbar': {
      height: 12
    },
    '::-webkit-scrollbar-thumb': {
      backgroundColor: palette.gray3
    },
    '::-webkit-scrollbar-track': {
      backgroundColor: palette.gray2,
      border: 'none'
    },
    'li:not(:first-of-type)': {
      marginLeft: 8
    },
    'li a': {
      width: 220
    }
  })
}

type CemeteriesProps = {
  title: string
  cemeteryItems:
    | PrefCityListCemeteries_prefectureCityCemeteries$data['items'][number][]
    | PrefectureListCemeteries_prefectureCemeteries$data['items'][number][]
    | PriorityCemeteries_cemetery$data['priority_cemeteries'][number][]
  eventLabel: string
}

export const Cemeteries: React.FC<CemeteriesProps> = ({
  cemeteryItems,
  title,
  eventLabel
}) => {
  return (
    <section>
      <h2 css={typography.headingXS}>{title}</h2>
      <ul css={styles.cemeteries}>
        {cemeteryItems.map((item, index) => (
          <li key={index}>
            <EventTracker
              clone
              label={eventLabel}
              action="click"
              elementCategory={elementCategories.similarCemeteryCard}
              elementLabel={item.cemeteryId}
            >
              <CemeteryCard cemetery={item} />
            </EventTracker>
          </li>
        ))}
      </ul>
    </section>
  )
}
