import { FC, useMemo } from 'react'
import parse, { HTMLReactParserOptions } from 'html-react-parser'
import { makeReplace } from './dependencies'
import { css, Global } from '@emotion/react'
import { mq } from '@lifedot/styles/mediaQuery'
import { palette } from '@lifedot/styles/palette'
import Head from 'next/head'

const styles = {
  postContent: css({
    wordBreak: 'break-word',
    p: {
      margin: '0 0 1.6rem'
    },
    table: {
      width: '100%',
      marginBottom: 30,
      fontSize: 14,
      [mq('max', 550)]: {
        width: '100%',
        WebkitOverflowScrolling: 'touch',
        overflowX: 'auto',
        fontSize: 12,
        position: 'relative'
      },
      'th,td': {
        border: `1px solid ${palette.gray2}`,
        padding: 8
      },
      th: {
        background: palette.background,
        fontWeight: 'bold',
        textAlign: 'center'
      },
      td: {
        background: palette.white
      }
    }
  })
}

interface PostContentProps {
  contentHtml: string
  replace?: HTMLReactParserOptions['replace']
}

export const PostContent: FC<PostContentProps> = ({ contentHtml, replace }) => {
  const parsed = useMemo(() => {
    return parse(contentHtml, { replace: makeReplace(replace) })
  }, [contentHtml, replace])

  return (
    <>
      <Head>
        <Global
          styles={{
            '@font-face': {
              fontFamily: 'Material Icons',
              fontStyle: 'normal',
              fontWeight: 400,
              fontDisplay: 'swap',
              src: "url(https://fonts.gstatic.com/s/materialicons/v90/flUhRq6tzZclQEJ-Vdg-IuiaDsNcIhQ8tQ.woff2) format('woff2')"
            }
          }}
        />
      </Head>
      <div css={styles.postContent}>{parsed}</div>
    </>
  )
}
