import { FC, useMemo } from 'react'
import { graphql } from 'relay-runtime'
import { useFragment } from 'react-relay/hooks'
import { LinksStation_stations$key } from './__generated__/LinksStation_stations.graphql'
import { LinksBase } from './LinksBase'
import { makeStationLinksData } from './dependencies'
import { typography } from '@lifedot/styles/typography'
import { AddMarginWrapper } from '@lifedot/components/AddMarginWrapper'

interface LinksStationProps {
  stations: LinksStation_stations$key
}

export const LinksStation: FC<LinksStationProps> = ({ stations }) => {
  const stationsData = useFragment(fragmentStations, stations)
  const links = useMemo(
    () => makeStationLinksData(stationsData),
    [stationsData]
  )

  if (links.length < 1) return null
  return (
    <AddMarginWrapper spacing={2}>
      <h2 css={typography.textL}>利用する駅から近い霊園・墓地を探す</h2>
      <LinksBase links={links} />
    </AddMarginWrapper>
  )
}

const fragmentStations = graphql`
  fragment LinksStation_stations on Cemetery {
    prefecture {
      roma
    }
    stations {
      stationId: id
      name
      railway_line_name
      distance
    }
  }
`
