import { ReactNode, FC } from 'react'
import { css } from '@emotion/react'
import { mq } from '@lifedot/styles/mediaQuery'

const styles = {
  ctaWrapper: css({
    padding: '40px 60px 36px',
    [mq('sp')]: {
      padding: '20px 12px 12px'
    }
  })
}

type CtaSectionProps = {
  children: ReactNode
}

export const CtaSection: FC<CtaSectionProps> = ({ children }) => {
  return <div css={styles.ctaWrapper}>{children}</div>
}
