import { FC } from 'react'
import {
  Breadcrumb as MikageBreadcrumb,
  BreadcrumbItem
} from '@lifedot/atoms/Breadcrumb'
import { graphql } from 'relay-runtime'
import { useFragment } from 'react-relay/hooks'
import { Breadcrumb_cemetery$key } from './__generated__/Breadcrumb_cemetery.graphql'
import { useBreadcrumbItemData } from './dependencies'
import { EventTracker } from '@lifedot/tracking'
import { elementCategories } from '@lifedot/constants/elementCategories'

interface BreadcrumbProps {
  cemetery: Breadcrumb_cemetery$key | null
}

export const Breadcrumb: FC<BreadcrumbProps> = ({ cemetery }) => {
  const cemeteryData = useFragment(fragment, cemetery)
  const breadcrumbItemData = useBreadcrumbItemData({ cemeteryData })

  return (
    <EventTracker
      label="breadCrumb_{innerText}"
      action="click"
      elementCategory={elementCategories.breadcrumb}
      elementLabel="{innerText}"
    >
      <MikageBreadcrumb>
        {breadcrumbItemData.map(({ href, name }, index) => (
          <BreadcrumbItem key={index} href={href} name={name} />
        ))}
      </MikageBreadcrumb>
    </EventTracker>
  )
}

const fragment = graphql`
  fragment Breadcrumb_cemetery on Cemetery {
    cemeteryId: id
    name
    nearest_station {
      stationId: id
      name
    }
    city {
      cityId: id
      name
    }
    designated_city {
      designatedCityId: id
      name
    }
    prefecture {
      roma
      name
    }
  }
`
