import { graphql } from 'relay-runtime'
import { useFragment } from 'react-relay/hooks'
import { SiteDescription as LibsSiteDescription } from '@lifedot/components/SiteDescription'
import { SiteDescription_cemetery$key } from './__generated__/SiteDescription_cemetery.graphql'
import { makeDescription } from './dependencies'

type SiteDescriptionProps = {
  cemetery: SiteDescription_cemetery$key
}

export const SiteDescription: React.FC<SiteDescriptionProps> = ({
  cemetery
}) => {
  const data = useFragment(fragment, cemetery)
  const description = makeDescription(
    data.name,
    data.prefecture.name,
    data.city.name
  )

  return <LibsSiteDescription description={description} />
}

const fragment = graphql`
  fragment SiteDescription_cemetery on Cemetery {
    name
    city {
      name
    }
    prefecture {
      name
    }
  }
`
