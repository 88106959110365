/**
 * @generated SignedSource<<a1e2466ecd67c0a43af33aa86657c4b5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime'
import { FragmentRefs } from 'relay-runtime'
export type PrefCityListCemeteries_cemetery$data = {
  readonly cemeteryId: number
  readonly city: {
    readonly name: string
  }
  readonly ' $fragmentType': 'PrefCityListCemeteries_cemetery'
}
export type PrefCityListCemeteries_cemetery$key = {
  readonly ' $data'?: PrefCityListCemeteries_cemetery$data
  readonly ' $fragmentSpreads': FragmentRefs<'PrefCityListCemeteries_cemetery'>
}

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: null,
  name: 'PrefCityListCemeteries_cemetery',
  selections: [
    {
      alias: 'cemeteryId',
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null
    },
    {
      alias: null,
      args: null,
      concreteType: 'CemeteryCity',
      kind: 'LinkedField',
      name: 'city',
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'name',
          storageKey: null
        }
      ],
      storageKey: null
    }
  ],
  type: 'Cemetery',
  abstractKey: null
}

;(node as any).hash = 'a131cff5aa6951a9e1de5a8e3719898f'

export default node
