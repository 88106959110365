import { cemeteryMainImage, deducePurchasePrice } from '@lifedot/utils'
import { ThirdPartyAdData_cemetery$data } from '@/components/ThirdPartyAdData/__generated__/ThirdPartyAdData_cemetery.graphql'

export const makeAdData = (
  data: ThirdPartyAdData_cemetery$data
): {
  sect: { name: string; code: string }
  sectionType: { name: string; code: string }
  cemeteryType: { name: string; code: string }
  price: { price: number | null }
  prefecture: { name: string }
  city: { name: string }
  name: string
  id: number
  code: string
  image: { path: string; alt: string }
} => {
  const [sect = { name: '', code: '' }] = data.sects
  const [sectionType = { name: '', code: '' }] = data.section_types
  const [cemeteryType = { name: '', code: '' }] = data.cemetery_types.filter(
    ({ code }) => code !== 'not_koei'
  )
  const price = Object.values(data.best_price_by_category).reduce<
    null | number
  >((res, price) => {
    if (!price) return res
    const purchasePrice = deducePurchasePrice(price)
    if (res && res < purchasePrice) return res
    return purchasePrice
  }, null)
  const { prefecture, city, name, cemeteryId: id, code, main_image } = data
  const image = cemeteryMainImage(main_image)

  return {
    sect,
    sectionType,
    cemeteryType,
    price: { price },
    prefecture,
    city,
    name,
    id,
    code,
    image
  }
}
