/**
 * @generated SignedSource<<e31a5e99c0621be141aaea8b1d5b567b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime'
import { FragmentRefs } from 'relay-runtime'
export type Breadcrumb_cemetery$data = {
  readonly cemeteryId: number
  readonly name: string
  readonly nearest_station: {
    readonly stationId: number | null
    readonly name: string | null
  }
  readonly city: {
    readonly cityId: number
    readonly name: string
  }
  readonly designated_city: {
    readonly designatedCityId: number | null
    readonly name: string | null
  }
  readonly prefecture: {
    readonly roma: string
    readonly name: string
  }
  readonly ' $fragmentType': 'Breadcrumb_cemetery'
}
export type Breadcrumb_cemetery$key = {
  readonly ' $data'?: Breadcrumb_cemetery$data
  readonly ' $fragmentSpreads': FragmentRefs<'Breadcrumb_cemetery'>
}

const node: ReaderFragment = (function () {
  var v0 = {
    alias: null,
    args: null,
    kind: 'ScalarField',
    name: 'name',
    storageKey: null
  }
  return {
    argumentDefinitions: [],
    kind: 'Fragment',
    metadata: null,
    name: 'Breadcrumb_cemetery',
    selections: [
      {
        alias: 'cemeteryId',
        args: null,
        kind: 'ScalarField',
        name: 'id',
        storageKey: null
      },
      v0 /*: any*/,
      {
        alias: null,
        args: null,
        concreteType: 'CemeteryNearestStation',
        kind: 'LinkedField',
        name: 'nearest_station',
        plural: false,
        selections: [
          {
            alias: 'stationId',
            args: null,
            kind: 'ScalarField',
            name: 'id',
            storageKey: null
          },
          v0 /*: any*/
        ],
        storageKey: null
      },
      {
        alias: null,
        args: null,
        concreteType: 'CemeteryCity',
        kind: 'LinkedField',
        name: 'city',
        plural: false,
        selections: [
          {
            alias: 'cityId',
            args: null,
            kind: 'ScalarField',
            name: 'id',
            storageKey: null
          },
          v0 /*: any*/
        ],
        storageKey: null
      },
      {
        alias: null,
        args: null,
        concreteType: 'CemeteryDesignatedCity',
        kind: 'LinkedField',
        name: 'designated_city',
        plural: false,
        selections: [
          {
            alias: 'designatedCityId',
            args: null,
            kind: 'ScalarField',
            name: 'id',
            storageKey: null
          },
          v0 /*: any*/
        ],
        storageKey: null
      },
      {
        alias: null,
        args: null,
        concreteType: 'CemeteryPrefecture',
        kind: 'LinkedField',
        name: 'prefecture',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'roma',
            storageKey: null
          },
          v0 /*: any*/
        ],
        storageKey: null
      }
    ],
    type: 'Cemetery',
    abstractKey: null
  }
})()

;(node as any).hash = 'f16d72113c1cfc86a0afe9be1c2e32a5'

export default node
