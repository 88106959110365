import { FC } from 'react'
import { css } from '@emotion/react'
import { Button } from '@lifedot/atoms/Button'
import { typography } from '@lifedot/styles/typography'
import { router } from '@lifedot/router'
import { ButtonLabel } from '@lifedot/components/ButtonLabel'

const styles = {
  anchor: css({
    position: 'relative',
    textDecoration: 'none',
    width: '100%',
    display: 'block',
    paddingTop: 4
  })
}

export const AreaRequestButton: FC = () => {
  return (
    <a css={styles.anchor} rel="nofollow" href={router.areaPartnerRequestNew()}>
      <Button
        mainText={
          <>
            <span css={typography.textL}>お墓の資料をもらう</span>
            <ButtonLabel>無料</ButtonLabel>
          </>
        }
        subText={<span css={typography.textS}>希望にあった他の</span>}
        color="accent"
        as="div"
      />
    </a>
  )
}
