import { graphql } from 'relay-runtime'
import { FC, useMemo } from 'react'
import { useFragment } from 'react-relay/hooks'
import { LinksCondition_conditions$key } from './__generated__/LinksCondition_conditions.graphql'
import { LinksCondition_conditionWithCounts$key } from './__generated__/LinksCondition_conditionWithCounts.graphql'
import { makeConditionLinksData } from './dependencies'
import { LinksBase } from './LinksBase'
import { typography } from '@lifedot/styles/typography'
import { AddMarginWrapper } from '@lifedot/components/AddMarginWrapper'

interface LinksConditionProps {
  conditions: LinksCondition_conditions$key
  conditionWithCounts: LinksCondition_conditionWithCounts$key
}

export const LinksCondition: FC<LinksConditionProps> = ({
  conditions,
  conditionWithCounts
}) => {
  const conditionsData = useFragment(fragmentConditions, conditions)
  const conditionWithCountsData = useFragment(
    fragmentConditionWithCounts,
    conditionWithCounts
  )
  const links = useMemo(
    () => makeConditionLinksData(conditionsData, conditionWithCountsData),
    [conditionWithCountsData, conditionsData]
  )

  if (links.length < 1) return null
  return (
    <AddMarginWrapper spacing={2}>
      <h2 css={typography.textL}>
        {conditionsData.city.name}にある同じ条件の霊園・墓地を探す
      </h2>
      <LinksBase links={links} />
    </AddMarginWrapper>
  )
}

const fragmentConditions = graphql`
  fragment LinksCondition_conditions on Cemetery {
    prefecture {
      roma
    }
    city {
      cityId: id
      name
    }
    choices {
      code
    }
  }
`

const fragmentConditionWithCounts = graphql`
  fragment LinksCondition_conditionWithCounts on SearchChoiceWithCemeteryCountConnection {
    items {
      cemeteryCount
      code
    }
  }
`
