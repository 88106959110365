import { FC, HTMLAttributes, useCallback, useEffect, useState } from 'react'
import { css } from '@emotion/react'
import { useBookmark } from '@lifedot/hooks/use-bookmark'
import { palette } from '@lifedot/styles/palette'
import { elevation } from '@lifedot/styles/elevation'
import AddCircleIcon from '@material-ui/icons/AddCircle'

const styles = {
  stock: css({
    backgroundColor: palette.white,
    borderRadius: 50,
    lineHeight: '44px',
    border: `1px solid ${palette.gray3}`,
    boxShadow: elevation[1],
    fontWeight: 'bold',
    cursor: 'pointer',
    textAlign: 'center',
    width: '100%'
  }),
  small: css({
    lineHeight: '32px',
    fontSize: 12,
    svg: css({
      fontSize: '1rem'
    })
  }),
  stocked: css({
    backgroundColor: palette.gray2,
    boxShadow: 'none',
    color: palette.gray6
  }),
  addIcon: css({
    color: palette.accent,
    verticalAlign: 'sub',
    marginRight: 4
  })
}

interface StockListButtonProps extends HTMLAttributes<HTMLButtonElement> {
  cemeteryId: number
  small?: boolean
}

export const StockListButton: FC<StockListButtonProps> = ({
  cemeteryId,
  small = false,
  ...props
}) => {
  const [, helper] = useBookmark()
  const handleBookmark = useCallback(() => {
    if (helper.isBookmark(cemeteryId)) helper.removeBookmark(cemeteryId)
    else helper.addBookmark(cemeteryId)
  }, [helper, cemeteryId])

  const [mounted, setMounted] = useState(false)
  useEffect(() => {
    setMounted(true)
  }, [])

  return (
    <button
      css={[
        styles.stock,
        small && styles.small,
        mounted && helper.isBookmark(cemeteryId) && styles.stocked
      ]}
      onClick={handleBookmark}
      {...props}
    >
      <AddCircleIcon css={styles.addIcon} fontSize="small" />
      {mounted && helper.isBookmark(cemeteryId)
        ? '検討リストに追加済み'
        : '検討リストに追加する'}
    </button>
  )
}
