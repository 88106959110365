import { LinksNearbyCity_nearbyCities$data } from '@/components/Links/__generated__/LinksNearbyCity_nearbyCities.graphql'
import { router } from '@lifedot/router'
import { LinksStation_stations$data } from '@/components/Links/__generated__/LinksStation_stations.graphql'
import { LinksCondition_conditions$data } from '@/components/Links/__generated__/LinksCondition_conditions.graphql'
import { LinksCondition_conditionWithCounts$data } from '@/components/Links/__generated__/LinksCondition_conditionWithCounts.graphql'
import { indexSearchChoices } from '@lifedot/constants/searchChoices'
import type { PrefCityListCemeteries_prefectureCityCemeteries$data } from './__generated__/PrefCityListCemeteries_prefectureCityCemeteries.graphql'
import type { PrefectureListCemeteries_prefectureCemeteries$data } from './__generated__/PrefectureListCemeteries_prefectureCemeteries.graphql'
import { useMemo } from 'react'

export type LinkData = Array<{
  href: string
  label: string
}>

export const makeNearbyCityLinksData = (
  cities: LinksNearbyCity_nearbyCities$data
): LinkData => {
  return cities.items
    .filter(({ cemeteryCount }) => cemeteryCount)
    .map(({ cityId, prefectureRoma, name }) => ({
      href: router.list({ prefectureRoma, cityId }),
      label: name
    }))
}

export const makeStationLinksData = (
  stations: LinksStation_stations$data
): LinkData => {
  return [...stations.stations]
    .sort(({ distance: a }, { distance: b }) => (a < b ? -1 : 1))
    .reduce<typeof stations.stations>((res, station) => {
      if (res.length >= 3) return res
      if (res.some(({ name }) => name === station.name)) return res
      return [...res, station]
    }, [])
    .map(({ stationId, name, railway_line_name: lineName }) => ({
      href: router.list({
        prefectureRoma: stations.prefecture.roma,
        stationId
      }),
      label: `${name}(${lineName})`
    }))
}

export const makeConditionLinksData = (
  conditions: LinksCondition_conditions$data,
  conditionWithCounts: LinksCondition_conditionWithCounts$data
): LinkData => {
  const hasCemeteryCodes = conditionWithCounts.items
    .filter(({ cemeteryCount }) => cemeteryCount)
    .map(({ code }) => code)
  const cemeteryCodes = conditions.choices.map(({ code }) => code)
  const indexCodes = indexSearchChoices.filter(
    ({ code }) =>
      hasCemeteryCodes.includes(code) && cemeteryCodes.includes(code)
  )
  return indexCodes.map(({ attributeCode, code, name }) => ({
    href: router.list({
      prefectureRoma: conditions.prefecture.roma,
      cityId: conditions.city.cityId,
      condition: { attributeCode, code }
    }),
    label: name
  }))
}

export const useListCemeteries = ({
  cemeteryId,
  items
}: {
  cemeteryId: number
  items:
    | PrefCityListCemeteries_prefectureCityCemeteries$data['items']
    | PrefectureListCemeteries_prefectureCemeteries$data['items']
}):
  | PrefCityListCemeteries_prefectureCityCemeteries$data['items'][number][]
  | PrefectureListCemeteries_prefectureCemeteries$data['items'][number][] => {
  return useMemo(
    () => items.filter((item) => item.cemeteryId !== cemeteryId).slice(0, 10),
    [items, cemeteryId]
  )
}
