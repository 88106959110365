import { css } from '@emotion/react'
import { palette } from '@lifedot/styles/palette'
import { FC } from 'react'

const styles = {
  note: css({
    padding: 8,
    backgroundColor: palette.gray2,
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 'bold'
  })
}

export const NotRequestableNote: FC = () => (
  <p css={styles.note}>
    当サイトではこちらの霊園の資料請求・
    <br />
    見学予約を受け付けておりません
  </p>
)
