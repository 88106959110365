/**
 * @generated SignedSource<<d5767f29b7c84d0ba7be4e01febc974b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime'
import { FragmentRefs } from 'relay-runtime'
export type CallToAction_cemetery$data = {
  readonly cemeteryId: number
  readonly requestable: boolean
  readonly prefecture: {
    readonly roma: string
  }
  readonly section_types: ReadonlyArray<{
    readonly code: string
  }>
  readonly ' $fragmentType': 'CallToAction_cemetery'
}
export type CallToAction_cemetery$key = {
  readonly ' $data'?: CallToAction_cemetery$data
  readonly ' $fragmentSpreads': FragmentRefs<'CallToAction_cemetery'>
}

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: null,
  name: 'CallToAction_cemetery',
  selections: [
    {
      alias: 'cemeteryId',
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'requestable',
      storageKey: null
    },
    {
      alias: null,
      args: null,
      concreteType: 'CemeteryPrefecture',
      kind: 'LinkedField',
      name: 'prefecture',
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'roma',
          storageKey: null
        }
      ],
      storageKey: null
    },
    {
      alias: null,
      args: null,
      concreteType: 'CemeteryChoice',
      kind: 'LinkedField',
      name: 'section_types',
      plural: true,
      selections: [
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'code',
          storageKey: null
        }
      ],
      storageKey: null
    }
  ],
  type: 'Cemetery',
  abstractKey: null
}

;(node as any).hash = '667af80735345988acac80ae1655d4c1'

export default node
