/**
 * @generated SignedSource<<3c69a4c455bdd95a4bdba7880f5be25b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime'
import { FragmentRefs } from 'relay-runtime'
export type LinksCondition_conditionWithCounts$data = {
  readonly items: ReadonlyArray<{
    readonly cemeteryCount: number
    readonly code: string
  }>
  readonly ' $fragmentType': 'LinksCondition_conditionWithCounts'
}
export type LinksCondition_conditionWithCounts$key = {
  readonly ' $data'?: LinksCondition_conditionWithCounts$data
  readonly ' $fragmentSpreads': FragmentRefs<'LinksCondition_conditionWithCounts'>
}

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: null,
  name: 'LinksCondition_conditionWithCounts',
  selections: [
    {
      alias: null,
      args: null,
      concreteType: 'SearchChoiceWithCemeteryCount',
      kind: 'LinkedField',
      name: 'items',
      plural: true,
      selections: [
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'cemeteryCount',
          storageKey: null
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'code',
          storageKey: null
        }
      ],
      storageKey: null
    }
  ],
  type: 'SearchChoiceWithCemeteryCountConnection',
  abstractKey: null
}

;(node as any).hash = '3ca60e033e84f1dbc37806e90085d704'

export default node
