import * as React from 'react'
import { css } from '@emotion/react'
import { PamphletButton } from '@/components/CallToAction/PamphletButton'
import { TourButton } from '@/components/CallToAction/TourButton'
import { Telephone } from '@/components/CallToAction/Telephone'
import { elementCategories } from '@lifedot/constants/elementCategories'
import { NotRequestableNote } from './NotRequestableNote'
import { AreaRequestButton } from '@/components/CallToAction/AreaRequestButton'
import { EventTracker } from '@lifedot/tracking'

const styles = {
  cta: css({
    display: 'grid',
    gap: 8
  }),
  buttons: css({
    display: 'grid',
    gap: 24,
    paddingTop: 8
  })
}

interface CallToActionProps {
  cemeteryId: number
  requestable?: boolean
  sectionTypes: { code: string }[]
  hideTelButton?: boolean
}

export const CallToAction: React.FC<CallToActionProps> = (props) => {
  const {
    cemeteryId,
    requestable = true,
    sectionTypes,
    hideTelButton = false
  } = props

  return (
    <div css={styles.cta}>
      {requestable ? (
        <div css={styles.buttons}>
          <PamphletButton
            cemeteryId={cemeteryId}
            data-event-tracking
            data-event-action="click"
            data-event-label={`cta_overview_パンフレット`}
            data-event-element-category={elementCategories.cta}
            data-event-element-label={`overview_パンフレット`}
            sectionTypes={[...sectionTypes]}
          />
          <TourButton
            cemeteryId={cemeteryId}
            data-event-tracking
            data-event-action="click"
            data-event-label={`cta_overview_見学`}
            data-event-element-category={elementCategories.cta}
            data-event-element-label={`overview_見学`}
          />
        </div>
      ) : (
        <>
          <NotRequestableNote />
          <EventTracker
            data-event-tracking
            data-event-element-category={elementCategories.anchor}
            label="cta_overview_一括資料請求（送客不可）"
            action="click"
            elementCategory={elementCategories.cta}
            elementLabel="overview_一括資料請求（送客不可）"
          >
            <AreaRequestButton />
          </EventTracker>
        </>
      )}
      {hideTelButton ? null : (
        <Telephone
          data-event-tracking
          data-event-action="click"
          data-event-label="overview_cta_電話"
          data-event-element-category={elementCategories.anchor}
          data-event-element-label="call"
          requestable={requestable}
          showNote
        />
      )}
    </div>
  )
}
