import { FC } from 'react'
import { PriorityCemeteries } from './PriorityCemeteries'
import { LinksStation } from './LinksStation'
import { LinksNearbyCity } from './LinksNearbyCity'
import { LinksCondition } from './LinksCondition'
import { LinksCondition_conditionWithCounts$key } from './__generated__/LinksCondition_conditionWithCounts.graphql'
import { LinksNearbyCity_nearbyCities$key } from './__generated__/LinksNearbyCity_nearbyCities.graphql'
import { AddMarginWrapper } from '@lifedot/components/AddMarginWrapper'
import { css } from '@emotion/react'
import { mq } from '@lifedot/styles/mediaQuery'
import { PrefectureListCemeteries } from './PrefectureListCemeteries'
import { PrefectureListCemeteries_prefectureCemeteries$key } from './__generated__/PrefectureListCemeteries_prefectureCemeteries.graphql'
import { PrefCityListCemeteries } from './PrefCityListCemeteries'
import { PrefCityListCemeteries_prefectureCityCemeteries$key } from './__generated__/PrefCityListCemeteries_prefectureCityCemeteries.graphql'
import { graphql } from 'relay-runtime'
import { useFragment } from 'react-relay/hooks'
import { Links_cemetery$key } from './__generated__/Links_cemetery.graphql'

const styles = {
  section: css({
    [mq('sp')]: {
      margin: '0 8px'
    }
  })
}

interface LinksProps {
  cemetery: Links_cemetery$key
  conditionWithCounts: LinksCondition_conditionWithCounts$key
  nearbyCities: LinksNearbyCity_nearbyCities$key
  prefectureCemeteries: PrefectureListCemeteries_prefectureCemeteries$key
  prefectureCityCemeteries: PrefCityListCemeteries_prefectureCityCemeteries$key
}

export const Links: FC<LinksProps> = ({
  cemetery,
  conditionWithCounts,
  nearbyCities,
  prefectureCemeteries,
  prefectureCityCemeteries
}) => {
  const data = useFragment(fragment, cemetery)
  return (
    <section css={styles.section}>
      <AddMarginWrapper spacing={6}>
        <PriorityCemeteries cemetery={data} />
        <PrefCityListCemeteries
          prefectureCityCemeteries={prefectureCityCemeteries}
          cemetery={data}
        />
        <PrefectureListCemeteries
          prefectureCemeteries={prefectureCemeteries}
          cemetery={data}
        />
        <AddMarginWrapper spacing={4}>
          <LinksCondition
            conditions={data}
            conditionWithCounts={conditionWithCounts}
          />
          <LinksStation stations={data} />
          <LinksNearbyCity nearbyCities={nearbyCities} />
        </AddMarginWrapper>
      </AddMarginWrapper>
    </section>
  )
}

const fragment = graphql`
  fragment Links_cemetery on Cemetery {
    ...PriorityCemeteries_cemetery
    ...PrefectureListCemeteries_cemetery
    ...PrefCityListCemeteries_cemetery
    ...LinksStation_stations
    ...LinksCondition_conditions
  }
`
