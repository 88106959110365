/**
 * @generated SignedSource<<8d8b38558982e909bd81a5374de7c2f6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime'
import { FragmentRefs } from 'relay-runtime'
export type LinksCondition_conditions$data = {
  readonly prefecture: {
    readonly roma: string
  }
  readonly city: {
    readonly cityId: number
    readonly name: string
  }
  readonly choices: ReadonlyArray<{
    readonly code: string
  }>
  readonly ' $fragmentType': 'LinksCondition_conditions'
}
export type LinksCondition_conditions$key = {
  readonly ' $data'?: LinksCondition_conditions$data
  readonly ' $fragmentSpreads': FragmentRefs<'LinksCondition_conditions'>
}

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: null,
  name: 'LinksCondition_conditions',
  selections: [
    {
      alias: null,
      args: null,
      concreteType: 'CemeteryPrefecture',
      kind: 'LinkedField',
      name: 'prefecture',
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'roma',
          storageKey: null
        }
      ],
      storageKey: null
    },
    {
      alias: null,
      args: null,
      concreteType: 'CemeteryCity',
      kind: 'LinkedField',
      name: 'city',
      plural: false,
      selections: [
        {
          alias: 'cityId',
          args: null,
          kind: 'ScalarField',
          name: 'id',
          storageKey: null
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'name',
          storageKey: null
        }
      ],
      storageKey: null
    },
    {
      alias: null,
      args: null,
      concreteType: 'CemeteryChoice',
      kind: 'LinkedField',
      name: 'choices',
      plural: true,
      selections: [
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'code',
          storageKey: null
        }
      ],
      storageKey: null
    }
  ],
  type: 'Cemetery',
  abstractKey: null
}

;(node as any).hash = '4b2ce442cf2304e965d35a25486130b8'

export default node
