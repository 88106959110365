import { type FC } from 'react'
import { graphql } from 'relay-runtime'
import { useFragment } from 'react-relay/hooks'
import { StickyTelephoneBanner_cemetery$key } from './__generated__/StickyTelephoneBanner_cemetery.graphql'
import { StickyWrapper } from '@lifedot/components/StickyWrapper'
import { TelephoneBanner } from '@/components/TelephoneBanner'
import { PrefectureRomas } from '@lifedot/constants/prefectures'
import { useIsUrban } from '@lifedot/hooks/use-is-urban'

interface StickyTelephoneBannerProps {
  cemetery: StickyTelephoneBanner_cemetery$key
}

export const StickyTelephoneBanner: FC<StickyTelephoneBannerProps> = ({
  cemetery
}) => {
  const { requestable, prefecture } = useFragment(fragment, cemetery)
  const isUrban = useIsUrban(prefecture.roma as PrefectureRomas)

  return isUrban && requestable ? (
    <StickyWrapper offset={1500} place="bottom">
      <TelephoneBanner />
    </StickyWrapper>
  ) : null
}

const fragment = graphql`
  fragment StickyTelephoneBanner_cemetery on Cemetery {
    requestable
    prefecture {
      roma
    }
  }
`
