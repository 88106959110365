import { FC } from 'react'
import { css } from '@emotion/react'
import { graphql } from 'relay-runtime'
import { useFragment } from 'react-relay/hooks'
import { mq } from '@lifedot/styles/mediaQuery'
import { StockListButton } from './StockListButton'
import { Telephone } from './Telephone'
import { PamphletButton } from './PamphletButton'
import { TourButton } from './TourButton'
import { CallToAction_cemetery$key } from './__generated__/CallToAction_cemetery.graphql'
import { elementCategories } from '@lifedot/constants/elementCategories'
import { AreaRequestButton } from '@/components/CallToAction/AreaRequestButton'
import { EventTracker } from '@lifedot/tracking'
import { PrefectureRomas } from '@lifedot/constants/prefectures'
import { useIsUrban } from '@lifedot/hooks/use-is-urban'

const styles = {
  cta: css({
    display: 'grid',
    gap: 16
  }),
  links: css({
    display: 'flex',
    gap: 12,
    [mq('sp')]: {
      flexDirection: 'column',
      gap: 24
    }
  }),
  stock: css({
    maxWidth: 320,
    marginInline: 'auto',
    width: '100%'
  }),
  button: css({
    width: '100%',
    maxWidth: 480,
    margin: 'auto'
  })
}

interface CallToActionProps {
  cemetery: CallToAction_cemetery$key
  eventTrack?: boolean
  hideStockListButton?: boolean
  eventLabelIndex?: string
}

export const CallToAction: FC<CallToActionProps> = ({
  cemetery,
  eventTrack = false,
  hideStockListButton = false,
  eventLabelIndex = ''
}) => {
  const { cemeteryId, requestable, section_types, prefecture } = useFragment(
    fragment,
    cemetery
  )

  const isUrban = useIsUrban(prefecture.roma as PrefectureRomas)

  return (
    <div css={styles.cta}>
      {requestable && (
        <div css={styles.links}>
          <PamphletButton
            data-event-tracking={eventTrack}
            data-event-action="click"
            data-event-label={`cta_${eventLabelIndex}パンフレット`}
            data-event-element-category={elementCategories.cta}
            data-event-element-label={`${eventLabelIndex}パンフレット`}
            cemeteryId={cemeteryId}
            sectionTypes={[...section_types]}
          />
          <TourButton
            data-event-tracking={eventTrack}
            data-event-action="click"
            data-event-label={`cta_${eventLabelIndex}見学`}
            data-event-element-category={elementCategories.cta}
            data-event-element-label={`${eventLabelIndex}見学`}
            cemeteryId={cemeteryId}
          />
        </div>
      )}
      {!requestable && (
        <div css={styles.button}>
          <EventTracker
            data-event-tracking
            data-event-element-category={elementCategories.anchor}
            label={`cta_${eventLabelIndex}_一括資料請求（送客不可）`}
            action="click"
            elementCategory={elementCategories.cta}
            elementLabel="一括資料請求（送客不可）"
          >
            <AreaRequestButton />
          </EventTracker>
        </div>
      )}
      {isUrban && requestable && (
        <Telephone
          data-event-tracking={eventTrack}
          data-event-action="click"
          data-event-label={`cta_${eventLabelIndex}電話`}
          data-event-element-category={elementCategories.anchor}
          data-event-element-label="call"
          showNote
          requestable={requestable}
        />
      )}
      {requestable && !hideStockListButton && (
        <div css={styles.stock}>
          <StockListButton
            data-event-tracking={eventTrack}
            data-event-action="click"
            data-event-label={`${eventLabelIndex}検討リスト`}
            data-event-element-category={elementCategories.stockList}
            data-event-element-label={`click_${cemeteryId}`}
            cemeteryId={cemeteryId}
          />
        </div>
      )}
    </div>
  )
}

const fragment = graphql`
  fragment CallToAction_cemetery on Cemetery {
    cemeteryId: id
    requestable
    prefecture {
      roma
    }
    section_types {
      code
    }
  }
`
