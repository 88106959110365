import { FC } from 'react'
import { graphql } from 'relay-runtime'
import { useFragment } from 'react-relay/hooks'
import { Anchors_cemetery$key } from './__generated__/Anchors_cemetery.graphql'
import { router } from '@lifedot/router'
import { css } from '@emotion/react'
import { palette } from '@lifedot/styles/palette'
import { typography } from '@lifedot/styles/typography'
import { mq, WindowWidth } from '@lifedot/styles/mediaQuery'
import { EventTracker } from '@lifedot/tracking'
import { elementCategories } from '@lifedot/constants/elementCategories'

const styles = {
  nav: css({
    [mq('min', WindowWidth.TABLET)]: {
      width: 860
    },
    [mq('max', WindowWidth.TABLET)]: {
      width: '100%'
    }
  }),
  list: css({
    display: 'flex',
    backgroundColor: palette.gray1,
    li: {
      width: '100%'
    },
    a: {
      display: 'block',
      padding: 8,
      textAlign: 'center',
      color: palette.main03,
      textDecoration: 'none',
      fontWeight: 'bold'
    }
  })
}

interface AnchorsProps {
  cemetery: Anchors_cemetery$key
}

export const Anchors: FC<AnchorsProps> = ({ cemetery }) => {
  const data = useFragment(fragment, cemetery)

  return (
    <nav css={styles.nav} data-cy="anchorLink">
      <ul css={[typography.textS, styles.list]}>
        {data.price_and_specs.length > 0 && (
          <li>
            <EventTracker
              clone
              label="anchor_価格情報"
              action="click"
              elementCategory={elementCategories.anchor}
              elementLabel="価格情報"
            >
              <a href={router.price(data.cemeteryId)}>価格情報</a>
            </EventTracker>
          </li>
        )}
        <li>
          <EventTracker
            clone
            label="anchor_特徴"
            action="click"
            elementCategory={elementCategories.anchor}
            elementLabel="特徴"
          >
            <a href={router.cemetery(data.cemeteryId) + '#a-about'}>特徴</a>
          </EventTracker>
        </li>
        {data.review_summary.count > 0 && (
          <li>
            <EventTracker
              clone
              label="anchor_口コミ"
              action="click"
              elementCategory={elementCategories.anchor}
              elementLabel="口コミ"
            >
              <a
                href={router.review(data.cemeteryId, data.review_summary.count)}
              >
                口コミ
              </a>
            </EventTracker>
          </li>
        )}
        <li>
          <EventTracker
            clone
            label="anchor_アクセス"
            action="click"
            elementCategory={elementCategories.anchor}
            elementLabel="アクセス"
          >
            <a href={router.access(data.cemeteryId)}>アクセス</a>
          </EventTracker>
        </li>
      </ul>
    </nav>
  )
}

const fragment = graphql`
  fragment Anchors_cemetery on Cemetery {
    cemeteryId: id
    review_summary {
      count
    }
    price_and_specs {
      priceId: id
    }
  }
`
