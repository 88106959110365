/**
 * @generated SignedSource<<c294f4d3a779b20ebd01cfe1ddfb847c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime'
import { FragmentRefs } from 'relay-runtime'
export type LinksNearbyCity_nearbyCities$data = {
  readonly items: ReadonlyArray<{
    readonly prefectureRoma: string
    readonly cityId: number
    readonly name: string
    readonly cemeteryCount: number
  }>
  readonly ' $fragmentType': 'LinksNearbyCity_nearbyCities'
}
export type LinksNearbyCity_nearbyCities$key = {
  readonly ' $data'?: LinksNearbyCity_nearbyCities$data
  readonly ' $fragmentSpreads': FragmentRefs<'LinksNearbyCity_nearbyCities'>
}

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: null,
  name: 'LinksNearbyCity_nearbyCities',
  selections: [
    {
      alias: null,
      args: null,
      concreteType: 'NearbyCityWithCemeteryCount',
      kind: 'LinkedField',
      name: 'items',
      plural: true,
      selections: [
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'prefectureRoma',
          storageKey: null
        },
        {
          alias: 'cityId',
          args: null,
          kind: 'ScalarField',
          name: 'id',
          storageKey: null
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'name',
          storageKey: null
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'cemeteryCount',
          storageKey: null
        }
      ],
      storageKey: null
    }
  ],
  type: 'NearbyCityWithCemeteryCountConnection',
  abstractKey: null
}

;(node as any).hash = '5bd5884605ba6c24215941a08259166c'

export default node
