/**
 * @generated SignedSource<<5a22cade9875904dec8582a6aecd9c19>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime'
import { FragmentRefs } from 'relay-runtime'
export type Links_cemetery$data = {
  readonly ' $fragmentSpreads': FragmentRefs<
    | 'PriorityCemeteries_cemetery'
    | 'PrefectureListCemeteries_cemetery'
    | 'PrefCityListCemeteries_cemetery'
    | 'LinksStation_stations'
    | 'LinksCondition_conditions'
  >
  readonly ' $fragmentType': 'Links_cemetery'
}
export type Links_cemetery$key = {
  readonly ' $data'?: Links_cemetery$data
  readonly ' $fragmentSpreads': FragmentRefs<'Links_cemetery'>
}

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: null,
  name: 'Links_cemetery',
  selections: [
    {
      args: null,
      kind: 'FragmentSpread',
      name: 'PriorityCemeteries_cemetery'
    },
    {
      args: null,
      kind: 'FragmentSpread',
      name: 'PrefectureListCemeteries_cemetery'
    },
    {
      args: null,
      kind: 'FragmentSpread',
      name: 'PrefCityListCemeteries_cemetery'
    },
    {
      args: null,
      kind: 'FragmentSpread',
      name: 'LinksStation_stations'
    },
    {
      args: null,
      kind: 'FragmentSpread',
      name: 'LinksCondition_conditions'
    }
  ],
  type: 'Cemetery',
  abstractKey: null
}

;(node as any).hash = 'a49a9c8989c7a7a8b06882cf2160a29d'

export default node
