import { FC } from 'react'
import { useRouter } from 'next/router'
import { graphql } from 'relay-runtime'
import { useFragment } from 'react-relay/hooks'
import type { PriorityCemeteries_cemetery$key } from './__generated__/PriorityCemeteries_cemetery.graphql'
import { Cemeteries } from './Cemeteries'

interface PriorityCemeteriesProps {
  cemetery: PriorityCemeteries_cemetery$key
}

export const PriorityCemeteries: FC<PriorityCemeteriesProps> = ({
  cemetery
}) => {
  const data = useFragment(fragment, cemetery)
  const { pathname } = useRouter()

  if (data.priority_cemeteries.length < 1) return null

  return (
    <Cemeteries
      eventLabel="cemeteryCard_条件の似た霊園"
      title={`${
        pathname.includes('/review')
          ? '口コミ評価の高い'
          : pathname.includes('/access')
          ? '近隣の'
          : ''
      }条件が似たオススメ霊園`}
      cemeteryItems={[...data.priority_cemeteries]}
    />
  )
}

const fragment = graphql`
  fragment PriorityCemeteries_cemetery on Cemetery {
    priority_cemeteries {
      cemeteryId: id
      ...useCemeteryTinyItem_cemetery
    }
  }
`
