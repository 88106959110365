import { AnchorHTMLAttributes, FC } from 'react'
import { css } from '@emotion/react'
import { Button } from '@lifedot/atoms/Button'
import { typography } from '@lifedot/styles/typography'
import Link from 'next/link'
import { router } from '@lifedot/router'
import { ButtonLabel } from '@lifedot/components/ButtonLabel'
import { isSectionTypeNormalOnly } from '@lifedot/utils'
import { SectionTypeCode } from '@lifedot/constants/searchChoices'

const styles = {
  anchor: css({
    position: 'relative',
    textDecoration: 'none',
    width: '100%',
    display: 'block'
  })
}

interface PamphletButtonProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  cemeteryId: number
  sectionTypes?: { code: string }[]
}

export const PamphletButton: FC<PamphletButtonProps> = ({
  cemeteryId,
  sectionTypes,
  ...props
}) => {
  return (
    <Link
      href={router.requestsNew({
        id: cemeteryId,
        ...(isSectionTypeNormalOnly(sectionTypes) && {
          section_types: [SectionTypeCode.NORMAL]
        })
      })}
      passHref
      prefetch={false}
    >
      <a css={styles.anchor} rel="nofollow" {...props}>
        <Button
          mainText={
            <>
              <span css={typography.textL}>パンフレットを請求する</span>
              <ButtonLabel>無料</ButtonLabel>
            </>
          }
          subText={<span css={typography.textS}>まずは</span>}
          color="accent"
          as="div"
        />
      </a>
    </Link>
  )
}
