import { css, SerializedStyles } from '@emotion/react'
import { transition } from '@lifedot/styles/transition'
import { elevation } from '@lifedot/styles/elevation'
import { mq } from '@lifedot/styles/mediaQuery'
import { palette } from '@lifedot/styles/palette'
import { typography } from '@lifedot/styles/typography'
import { memorialJewerlyStyles } from './memorialJewerlyStyles'
import { rgba } from 'emotion-rgba'
import { zIndex } from '@lifedot/styles/zIndex'

// TODO: followingBannerを一定スクロールで表示されるように

const postHd = css({
  fontWeight: 'bold',
  lineHeight: 1.4,
  color: palette.black
})

const box = css({
  padding: '20px 16px 0 16px',
  backgroundColor: '#fffefd',
  borderRadius: 4,
  margin: '0 0 20px 0',
  boxSizing: 'border-box',
  border: `2px solid ${palette.gray2}`,
  width: '100%',
  position: 'relative',
  '::before': {
    fontFamily: 'Material Icons',
    color: palette.white,
    position: 'absolute',
    textAlign: 'center',
    top: -16,
    left: -8,
    width: 32,
    height: 32,
    lineHeight: '32px',
    background: palette.main,
    borderRadius: '50%',
    fontSize: 16
  }
})

const visualBox = css(box, {
  padding: 0,
  margin: '0 4px 16px',
  display: 'flex',
  flexDirection: 'column',
  '@media screen and (max-width: 767px)': {
    flexWrap: 'wrap',
    width: 'calc(100% / 2 - 8px)'
  },
  '@media screen and (max-width: 550px)': {
    width: '100%',
    margin: '0 0 16px'
  }
})

const box__title = css({
  fontWeight: 'bold',
  borderBottom: `1px solid ${palette.gray2}`,
  paddingBottom: 8,
  marginBottom: '12px !important '
})

const btn = css({
  fontWeight: 'bold',
  padding: '8px 12px',
  textAlign: 'center',
  display: 'inline-block',
  textDecoration: 'none !important', //記事対策用
  cursor: 'pointer',
  borderRadius: 4,
  transition: transition('easeOut'),
  ':active': {
    boxShadow: 'none',
    transform: 'translateY(4px)'
  }
})

const followingBanner = css({
  bottom: 0,
  zIndex: zIndex.footer,
  position: 'fixed',
  cursor: 'pointer',
  boxShadow: elevation[1]
})

export const replaceableStyles: Record<string, SerializedStyles> = {
  /*-------------------------------------------------------
    見出し
  ---------------------------------------------------------*/
  'postHd--h1': css(postHd, typography.headingXL, {
    margin: '1em 0',
    clear: 'both'
  }),
  'postHd--h2': css(postHd, typography.headingL, {
    background: palette.gray1,
    borderLeft: `10px solid ${palette.main}`,
    borderRadius: 2,
    position: 'relative',
    margin: '2em 0 1em',
    padding: '1em 0.5em',
    clear: 'both'
  }),
  'postHd--h3': css(postHd, typography.headingM, {
    position: 'relative',
    borderBottom: `solid 3px ${palette.gray2}`,
    padding: '4px 0px',
    margin: '2em 0 1em',
    '&::after': {
      content: '""',
      position: 'absolute',
      background: palette.main,
      height: 3,
      left: 0,
      bottom: -3,
      width: '20%'
    }
  }),
  'postHd--h4': css(postHd, typography.headingS, {
    margin: '2em 0 1em',
    marginTop: 15,
    paddingLeft: 8,
    borderLeft: `4px solid ${palette.main}`
  }),
  'postHd--h5': css(postHd, typography.headingXS, {
    margin: '2em 0 1em',
    marginTop: 15,
    paddingLeft: 20,
    position: 'relative',
    '&::after': {
      content: '""',
      position: 'absolute',
      background: palette.main,
      top: 'calc(50% - 3px)',
      left: 0,
      height: 6,
      width: 12
    }
  }),
  'postHd--h6': css(postHd, typography.textS, {
    margin: '2em 0 1em'
  }),
  marker: css({
    fontWeight: 'bold',
    background: palette.marker,
    padding: 2
  }),
  markerOrange: css({
    fontWeight: 'bold',
    background: `linear-gradient(transparent 60%, ${palette.main} 0%)`
  }),
  markerRed: css({
    fontWeight: 'bold',
    background: `linear-gradient(transparent 60%, ${palette.alert} 0%)`
  }),
  txtRed: css({
    color: palette.alert
  }),
  txtOrange: css({
    color: palette.main03
  }),
  txtGreen: css({
    color: palette.accent02
  }),
  txtGray: css({
    color: palette.gray6
  }),
  caption: css(typography.textS, {
    color: palette.gray6
  }),
  'c-sectionTitle': css({
    marginBottom: 8
  }),
  'c-alignLeft': css({
    textAlign: 'left'
  }),
  'c-alignRight': css({
    textAlign: 'right'
  }),
  'c-alignCenter': css({
    textAlign: 'center'
  }),
  postTable: css({
    width: '100%',
    marginBottom: 30,
    fontSize: 14,
    [mq('max', 550)]: {
      whiteSpace: 'normal'
    },
    'th, td': {
      border: `1px solid ${palette.gray2}`,
      padding: 8
    },
    th: {
      background: palette.background,
      fontWeight: 'bold',
      textAlign: 'center'
    }
  }),
  /*-------------------------------------------------------
    List
  ---------------------------------------------------------*/
  uList: css({
    listStyle: 'none',
    margin: '0 0 25px',
    padding: '0 0 0 20px',
    li: {
      margin: '6px 0',
      paddingLeft: 2,
      position: 'relative',
      '::before': {
        fontFamily: 'Material Icons',
        content: '"\\ef4a"',
        marginLeft: -16,
        paddingRight: 6,
        display: 'inline-block',
        color: palette.main,
        fontSize: 8,
        position: 'relative',
        top: 0,
        left: -2
      }
    }
  }),
  uListAttention: css({
    listStyle: 'none',
    margin: '0 0 25px',
    padding: '0 0 0 20px',
    li: {
      margin: '6px 0',
      paddingLeft: 2,
      position: 'relative',
      '::before': {
        fontFamily: 'Material Icons',
        content: '"\\ef4a"',
        marginLeft: -16,
        paddingRight: 6,
        display: 'inline-block',
        color: palette.alert,
        fontSize: 8,
        position: 'relative',
        top: 0,
        left: -2
      }
    }
  }),
  oList: css({
    listStyle: 'none',
    padding: '0 0 0 20px',
    margin: '0 0 25px',
    position: 'relative',
    counterReset: 'oListNum',
    li: {
      margin: '6px 0',
      paddingLeft: 12,
      position: 'relative',
      '::before': {
        marginLeft: -12,
        padding: 1,
        display: 'block',
        position: 'absolute',
        counterIncrement: 'oListNum',
        content: 'counter(oListNum)',
        fontSize: 10,
        width: 16,
        height: 16,
        top: 2,
        left: -2,
        borderRadius: 2,
        border: `1px solid ${palette.main}`,
        backgroundColor: palette.main,
        textAlign: 'center'
      }
    }
  }),
  oListAttention: css({
    listStyle: 'none',
    padding: '0 0 0 20px',
    margin: '0 0 25px',
    position: 'relative',
    counterReset: 'oListNum',
    li: {
      margin: '6px 0',
      paddingLeft: 12,
      position: 'relative',
      '::before': {
        marginLeft: -12,
        padding: 1,
        display: 'block',
        position: 'absolute',
        counterIncrement: 'oListNum',
        content: 'counter(oListNum)',
        fontSize: 10,
        width: 16,
        height: 16,
        top: 2,
        left: -2,
        borderRadius: 2,
        border: `1px solid ${palette.alert}`,
        backgroundColor: palette.alert,
        textAlign: 'center',
        color: palette.white
      }
    }
  }),
  oListFlow: css({
    listStyle: 'none',
    padding: '0 20px',
    margin: '0 0 25px',
    position: 'relative',
    li: {
      border: '2px solid #ffe1a5',
      borderRadius: 4,
      textAlign: 'center',
      padding: '8px 12px 6px',
      marginBottom: 35,
      position: 'relative',
      '::after': {
        borderTop: `16px solid ${palette.main}`,
        borderLeft: '16px solid transparent',
        borderRight: '16px solid transparent',
        content: '""',
        position: 'absolute',
        bottom: -27,
        left: '50%',
        marginLeft: -16
      },
      ':last-of-type': {
        margin: 0,
        '::after': {
          display: 'none'
        }
      }
    }
  }),
  'post-accordion__input': css({
    display: 'none',
    ':checked': {
      '+ label::after': {
        transform: 'rotate(135deg)',
        top: 18
      },
      '+ label + div': {
        maxHeight: 10000
      }
    }
  }),
  'post-accordion__label': css(typography.textL, {
    display: 'block',
    cursor: 'pointer',
    marginBottom: 8,
    position: 'relative',
    border: `1px solid ${palette.gray2}`,
    borderRadius: 4,
    padding: '8px 40px 8px 12px',
    boxShadow: elevation[1],
    fontWeight: 'bold',
    '::after': {
      content: '""',
      position: 'absolute',
      right: 16,
      top: 14,
      width: 5,
      height: 5,
      border: '2px solid',
      borderColor: `transparent transparent ${palette.gray6} ${palette.gray6}`,
      transform: 'rotate(-45deg)'
    }
  }),
  'post-accordion__contents': css({
    maxHeight: 0,
    overflow: 'hidden',
    transition: transition('easeInOut')
  }),
  interviewBalloons: css({
    marginBottom: 24
  }),
  interviewBalloons__left: css({
    marginLeft: 4,
    marginTop: -1,
    float: 'left',
    width: 70,
    height: 70,
    '::after': {
      clear: 'both',
      content: '""',
      display: 'block'
    },
    '::before': {
      clear: 'both',
      content: '""',
      display: 'block'
    }
  }),
  interviewBalloons__right: css({
    marginRight: 4,
    marginTop: -1,
    float: 'right',
    width: 70,
    height: 70
  }),
  interviewBalloons__img: css({
    width: '100%',
    height: '100%',
    border: `2px solid ${palette.white}`,
    borderRadius: '50%',
    margin: 0
  }),
  interviewBalloons__description: css({
    padding: '5px 0 0',
    fontSize: 13,
    fontWeight: 'bold',
    textAlign: 'center'
  }),
  interviewBalloons__text__right: css({
    position: 'relative',
    marginLeft: 100,
    marginRight: 100,
    padding: '16px 16px 0px',
    border: `3px solid ${palette.main}`,
    backgroundColor: palette.background,
    borderRadius: 5,
    [mq('max', 480)]: {
      marginRight: 0
    },
    '::before': {
      position: 'absolute',
      content: '""',
      border: '10px solid transparent',
      borderRight: `10px solid ${palette.main}`,
      top: 21,
      left: -23
    },
    '::after': {
      position: 'absolute',
      content: '""',
      border: '10px solid transparent',
      borderRight: `10px solid ${palette.background}`,
      top: 21,
      left: -19
    }
  }),
  interviewBalloons__text__left: css({
    position: 'relative',
    marginLeft: 100,
    marginRight: 100,
    padding: '16px 16px 0px',
    border: `3px solid ${palette.accent}`,
    backgroundColor: palette.background,
    borderRadius: 5,
    [mq('max', 480)]: {
      marginLeft: 0
    },
    '::before': {
      position: 'absolute',
      content: '""',
      border: '10px solid transparent',
      borderLeft: `10px solid ${palette.accent}`,
      top: 21,
      right: -23
    },
    '::after': {
      position: 'absolute',
      content: '""',
      border: '10px solid transparent',
      borderLeft: `10px solid ${palette.background}`,
      top: 21,
      right: -19
    }
  }),
  /*-------------------------------------------------------
    Box
  ---------------------------------------------------------*/
  accentBox: css(box),
  accentBox__title: css(box__title),
  introBox: css(box, {
    '::before': {
      fontFamily: 'Material Icons',
      color: palette.white,
      position: 'absolute',
      textAlign: 'center',
      top: -16,
      left: -8,
      width: 32,
      height: 32,
      lineHeight: '32px',
      background: palette.main,
      borderRadius: '50%',
      content: '"\\e0f0"', // これ帰る
      fontSize: 20
    },
    ul: {
      listStyle: 'none',
      margin: '0 0 25px',
      padding: '0 0 0 20px',
      li: {
        margin: '6px 0',
        paddingLeft: 6,
        position: 'relative',
        '::before': {
          fontFamily: 'Material Icons',
          marginLeft: -16,
          display: 'block',
          position: 'absolute',
          content: '"\\e5ca"',
          color: '#ffc758',
          fontSize: 16,
          top: 3,
          left: -4
        }
      }
    }
  }),
  introBox__title: css(box__title, {}),
  pointBox: css(box, {
    background: palette.background,
    paddingTop: 50,
    border: `2px solid ${palette.main}`
  }),
  pointBox__title: css({
    position: 'absolute',
    background: palette.main03,
    color: palette.white,
    padding: '4px 36px 4px 28px',
    top: 8,
    left: -10,
    fontSize: 16,
    fontWeight: 'bold',
    letterSpacing: 2,
    '&::before': {
      position: 'absolute',
      content: '""',
      top: '100%',
      left: 0,
      border: 'none',
      borderBottom: 'solid 12px transparent',
      borderRight: `solid 10px ${palette.gray4}`
    },
    '&::after': {
      position: 'absolute',
      content: '""',
      top: 0,
      right: 0,
      borderWidth: '17px 12px 17px 0',
      borderColor: `transparent ${palette.background} transparent transparent`,
      borderStyle: 'solid'
    }
  }),
  pointBox__contents: css({
    marginBottom: 12
  }),
  boxWrap: css({
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: 25
  }),
  postBlockquote: css(box, {
    '::before': {
      content: '"\\e244"'
    }
  }),
  postBlockquote__cite: css({
    display: 'block',
    fontSize: 12,
    textAlign: 'right',
    color: palette.main03,
    marginBottom: 12
  }),
  inPageLinkBox: css({
    border: `2px solid ${palette.main}`,
    borderRadius: 2,
    margin: '0 4px 8px',
    width: 'calc(100% / 3 - 8px)',
    [mq('max', 767)]: {
      width: '100%',
      margin: '0 0 8px'
    },
    ul: {
      listStyle: 'none',
      padding: '12px 8px',
      li: {
        paddingLeft: 20,
        fontSize: 14,
        ':not(:first-of-type)': {
          marginTop: 8
        },
        '::before': {
          fontFamily: 'Material Icons',
          marginLeft: -16,
          paddingRight: 4,
          verticalAlign: 'middle',
          content: '"\\ef4a"',
          color: palette.main,
          fontSize: 10
        }
      }
    }
  }),
  inPageLinkBox__title: css({
    background: rgba(palette.main, 0.2),
    padding: '12px 16px',
    lineHeight: '20px'
  }),
  specBox: css(box, {
    padding: 0,
    borderRadius: 4,
    borderColor: palette.gray1
  }),
  specBox__title: css({
    fontWeight: 'bold',
    fontSize: 18,
    background: palette.gray1,
    padding: '8px 12px',
    margin: '0 !important'
  }),
  specBox__contents: css({
    display: 'flex',
    padding: 12,
    [mq('sp')]: {
      flexDirection: 'column'
    }
  }),
  specBox__disc: css({
    width: '40%',
    flexShrink: 0,
    [mq('sp')]: {
      width: '100%'
    },
    p: {
      fontSize: 14,
      marginBottom: 8
    }
  }),
  specBox__disc__img: css({
    marginBottom: 8
  }),
  specBox__summary: css({
    marginLeft: 8,
    width: '100%',
    [mq('sp')]: {
      margin: 0
    }
  }),
  specBox__merit: css(box, {
    padding: '12px 16px',
    marginBottom: 12,
    background: palette.background,
    border: `1px solid ${palette.main}`,
    dd: {
      marginLeft: 20,
      '::before': {
        fontFamily: 'Material Icons',
        marginLeft: -16,
        paddingRight: 6,
        content: '"\\e5ca"',
        verticalAlign: 'middle',
        color: palette.main,
        fontSize: 16
      }
    }
  }),
  specBox__merit__title: css({
    marginBottom: 4,
    fontSize: 18
  }),
  specBox__demerit: css({
    dd: {
      marginLeft: 24,
      fontSize: 14,
      '::before': {
        fontFamily: 'Material Icons',
        marginLeft: -16,
        paddingRight: 6,
        content: '"\\ef4a"',
        verticalAlign: 'middle',
        color: palette.alert,
        fontSize: 8
      }
    }
  }),
  specBox__demerit__title: css({
    fontSize: 14,
    paddingLeft: 8,
    color: palette.alert,
    fontWeight: 'bold'
  }),
  'visualBox--3column': css(visualBox, {
    width: 'calc(100% / 3 - 8px)'
  }),
  'visualBox--4column': css(visualBox, {
    width: 'calc(100% / 4 - 8px)'
  }),
  visualBox__img: css({
    borderRadius: '2px 2px 0 0',
    overflow: 'hidden',
    width: '100%',
    img: {
      verticalAlign: 'bottom'
    },
    '@media screen and (max-width: 767px)': {
      order: 1
    }
  }),
  visualBox__title: css({
    width: '100%',
    background: palette.gray1,
    margin: '0 !important',
    padding: 8,
    fontWeight: 'bold',
    '@media screen and (max-width: 767px)': {
      order: 2,
      borderRadius: '2px 2px 0 0'
    }
  }),
  visualBox__disc: css({
    padding: 8,
    margin: '0 !important',
    fontSize: 14,
    '@media screen and (max-width: 767px)': {
      order: 3
    }
  }),
  recommendBox: css(box, {
    border: `2px solid ${palette.main}`,
    background: palette.white,
    '&::before': {
      fontFamily: 'Material Icons',
      color: palette.white,
      position: 'absolute',
      textAlign: 'center',
      top: -16,
      left: -8,
      width: 32,
      height: 32,
      lineHeight: '32px',
      background: palette.main,
      borderRadius: '50%',
      fontSize: 16,
      content: '"\\e8dc"'
    }
  }),
  recommendBox__title: css(box__title, {
    color: palette.main03,
    borderBottom: `1px solid ${palette.main}`
  }),
  attentionBox: css(box, {
    border: `2px solid ${rgba(palette.alert, 0.3)}`,
    background: palette.white
  }),
  attentionBox__title: css(box__title, {
    borderBottom: `1px solid ${rgba(palette.alert, 0.3)}`
  }),
  'c-meritBox': css({
    border: `2px solid ${palette.merit}`,
    borderRadius: 4,
    ul: {
      margin: '12px 20px',
      li: {
        marginLeft: 20,
        position: 'relative',
        '&:not(:first-of-type)': {
          margin: '8px 0 0 20px'
        },
        '&::before': {
          position: 'absolute',
          fontFamily: 'Material Icons',
          content: '"\\ef4a"',
          color: palette.merit,
          fontSize: 8,
          top: 8,
          left: -18
        }
      }
    }
  }),
  'c-meritBox__title': css({
    background: palette.merit,
    color: palette.white,
    padding: '8px 20px',
    margin: '0 !important',
    '&::before': {
      fontFamily: 'Material Icons',
      content: '"\\e8dc"',
      marginRight: 8
    }
  }),
  'c-meritBox--demerit': css({
    border: `2px solid ${palette.alert}`,
    borderRadius: 4,
    ul: {
      margin: '12px 20px',
      li: {
        marginLeft: 20,
        position: 'relative',
        '&:not(:first-of-type)': {
          margin: '8px 0 0 20px'
        },
        '&::before': {
          position: 'absolute',
          fontFamily: 'Material Icons',
          content: '"\\ef4a"',
          color: palette.alert,
          fontSize: 8,
          top: 8,
          left: -18
        }
      }
    }
  }),
  'c-meritBox__title--demerit': css({
    background: palette.alert,
    color: palette.white,
    padding: '8px 20px',
    margin: '0 !important',
    '&::before': {
      fontFamily: 'Material Icons',
      content: '"\\e8db"',
      marginRight: 8
    }
  }),
  dictionaryBox: css(box, {
    '&::before': {
      fontFamily: 'Material Icons',
      color: palette.white,
      position: 'absolute',
      textAlign: 'center',
      top: -16,
      left: -8,
      width: 32,
      height: 32,
      lineHeight: '32px',
      background: palette.main,
      borderRadius: '50%',
      fontSize: 16,
      content: '"\\e866"'
    }
  }),
  dictionaryBox__term: css({
    fontWeight: 'bold',
    borderBottom: `1px solid ${palette.gray2}`,
    paddingBottom: 8
  }),
  editorCommentBox: css({
    margin: '16px 0 24px'
  }),
  editorCommentBox__prof: css({
    display: 'flex',
    alignItems: 'center',
    marginBottom: 16
  }),
  editorCommentBox__prof__img: css({
    width: 80,
    height: 80,
    borderRadius: '50%',
    overflow: 'hidden'
  }),
  editorCommentBox__prof__detail: css(typography.textS, {
    margin: '0 0 0 8px !important',
    fontWeight: 'bold',
    color: palette.black
  }),
  editorCommentBox__comment: css({
    position: 'relative',
    padding: 16,
    border: `2px solid ${palette.gray2}`,
    borderRadius: 4,
    background: palette.white,
    '&::before': {
      content: '""',
      position: 'absolute',
      top: -26,
      left: 28,
      border: '10px solid transparent',
      borderBottom: `16px solid ${palette.gray2}`
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      top: -22,
      left: 28,
      border: '10px solid transparent',
      borderBottom: `16px solid ${palette.white}`
    }
  }),
  ctaBox: css(box, {
    boxShadow: elevation[1],
    border: `2px solid ${palette.main}`,
    marginBottom: 36,
    padding: 0
  }),
  ctaBox__titleLabel: css({
    color: palette.white,
    marginBottom: 4,
    backgroundColor: palette.gray6,
    padding: '4px 8px',
    borderRadius: 2,
    verticalAlign: 'top'
  }),
  ctaBox__title: css(typography.headingS, {
    borderBottom: `1px solid ${palette.main}`,
    background: palette.background,
    margin: '0 !important',
    padding: '8px 16px',
    borderRadius: '2px 2px 0 0',
    color: palette.black
  }),
  ctaBox__content: css({
    padding: '16px 16px 0'
  }),
  ctaBox__btn: css({
    textAlign: 'center',
    marginBottom: 32,
    padding: '0 24px 12px',
    a: {
      padding: '12px 24px'
    }
  }),
  /*-------------------------------------------------------
    Layout
  ---------------------------------------------------------*/
  'l-responsiveColumn': css({
    display: 'flex',
    marginBottom: 8,
    [mq('sp')]: {
      flexDirection: 'column'
    }
  }),
  'l-responsiveColumn__picture': css({
    width: '40%',
    img: {
      objectFit: 'cover',
      width: '100%'
    },
    [mq('sp')]: {
      width: '100%',
      marginBottom: 8
    }
  }),
  'l-responsiveColumn__caption': css({
    margin: '0 0 0 8px !important',
    flex: 1,
    [mq('sp')]: {
      margin: '0 !important'
    }
  }),
  'l-floatBtn': css({
    margin: '16px auto',
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap'
  }),
  'l-1FloatBtn': css({
    margin: '16px auto',
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center'
  }),
  'l-1FloatBtn__item': css({
    width: '60%',
    maxWidth: 400,
    minWidth: 240,
    margin: '0 1%',
    [mq('sp')]: {
      width: '90%',
      maxWidth: 320
    }
  }),
  'l-2FloatBtn': css({
    margin: '16px auto',
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between'
  }),
  'l-2FloatBtn__item': css({
    width: '48%',
    margin: '0 1%'
  }),
  'l-3FloatBtn': css({
    margin: '16px auto',
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between'
  }),
  'l-3FloatBtn__item': css({
    width: '31%',
    margin: '0 1%'
  }),
  'l-section--small': css({
    marginBottom: 20
  }),
  'l-section--big': css({
    marginBottom: 40
  }),
  /*-------------------------------------------------------
    FontSize
  ---------------------------------------------------------*/
  'c-textXL': css({
    fontSize: 20,
    lineHeight: 1.6,
    '@media screen and (max-width: 767px)': {
      fontSize: 18
    }
  }),
  'c-textL': css({
    fontSize: 18,
    lineHeight: 1.6,
    '@media screen and (max-width: 767px)': {
      fontSize: 16
    }
  }),
  'c-textM': css({
    fontSize: 16,
    lineHeight: 1.6,
    '@media screen and (max-width: 767px)': {
      fontSize: 14
    }
  }),
  'c-textS': css({
    fontSize: 14,
    lineHeight: 1.6,
    '@media screen and (max-width: 767px)': {
      fontSize: 12
    }
  }),
  'c-textXS': css({
    fontSize: 12,
    lineHeight: 1.6,
    '@media screen and (max-width: 767px)': {
      fontSize: 10
    }
  }),
  'c-headingXL': css({
    fontSize: 32,
    fontWeight: 'bold',
    lineHeight: 1.4,
    '@media (max-width: 767px)': {
      fontSize: 26
    }
  }),
  'c-headingL': css({
    fontSize: 28,
    fontWeight: 'bold',
    lineHeight: 1.4,
    '@media (max-width: 767px)': {
      fontSize: 24
    }
  }),
  'c-headingM': css({
    fontSize: 26,
    fontWeight: 'bold',
    lineHeight: 1.4,
    '@media (max-width: 767px)': {
      fontSize: 22
    }
  }),
  'c-headingS': css({
    fontSize: 24,
    fontWeight: 'bold',
    lineHeight: 1.4,
    '@media (max-width: 767px)': {
      fontSize: 20
    }
  }),
  'c-headingXS': css({
    fontSize: 22,
    fontWeight: 'bold',
    lineHeight: 1.4,
    '@media (max-width: 767px)': {
      fontSize: 18
    }
  }),
  /*-------------------------------------------------------
    Hr
  ---------------------------------------------------------*/
  postHr: css({
    border: 'none',
    borderBottom: `1px solid ${palette.gray2}`,
    margin: '20px 0'
  }),
  /*-------------------------------------------------------
    Utility
  ---------------------------------------------------------*/
  aligncenter: css({
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center',
    clear: 'both'
  }),
  alignright: css({
    marginLeft: 12,
    marginBottom: 12,
    textAlign: 'right'
  }),
  alignleft: css({
    marginRight: 12,
    marginBottom: 12
  }),
  'u-resetParagraphMargin': css({
    marginBottom: '0 !important'
  }),
  'u-sp': css({
    display: 'none',
    [mq('sp')]: {
      display: 'block'
    }
  }),
  'u-pc': css({
    display: 'block',
    [mq('sp')]: {
      display: 'none'
    }
  }),
  /*-------------------------------------------------------
    PickUpCemetery
  ---------------------------------------------------------*/
  'p-postRecommend': css({
    marginTop: 80,
    padding: '0 20px 8px',
    border: `2px solid ${palette.main}`,
    [mq('sp')]: {
      padding: '0 12px 4px'
    }
  }),
  'p-postRecommend__title': css(typography.textXL, {
    position: 'relative',
    display: 'inline-block',
    top: -28,
    left: '50%',
    marginBottom: '0 !important',
    padding: '4px 20px',
    backgroundColor: palette.main03,
    color: palette.white,
    textAlign: 'center',
    fontWeight: 'bold',
    transform: 'translateX(-50%)',
    [mq('sp')]: {
      padding: '4px 8px'
    },
    '::before, ::after': {
      display: 'block',
      content: '""',
      position: 'absolute',
      top: 0,
      borderWidth: '29px 24px 30px 24px',
      borderColor: `${palette.main03} transparent ${palette.main03} transparent`,
      borderStyle: 'solid',
      [mq('sp')]: {
        borderWidth: '26px 12px 26px 12px'
      }
    },
    '::before': {
      right: -24,
      [mq('sp')]: {
        right: -12
      }
    },
    '::after': {
      left: -24,
      [mq('sp')]: {
        left: -12
      }
    }
  }),
  'p-postRecommend__title__supplement': css(typography.textXS, {
    display: 'block',
    fontWeight: 'normal'
  }),
  'p-postRecommend__list': css({
    marginTop: -8
  }),
  'p-postRecommend__listItem': css({
    paddingBottom: 12,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    ':not(:first-of-type)': {
      paddingTop: 12,
      borderTop: `1px solid ${palette.gray2}`
    }
  }),
  'p-postRecommend__listItem__information': css({
    display: 'flex',
    flex: 4,
    [mq('sp')]: {
      flex: 3
    },
    img: {
      flex: '0 0 132px',
      height: 74,
      objectFit: 'cover',
      marginRight: 8,
      [mq('sp')]: {
        flex: '0 0 72px',
        height: 48
      },
      [mq('max', 320)]: {
        flex: '0 0 64px',
        height: 42
      }
    }
  }),
  'p-postRecommend__listItem__information__name': css(typography.textL, {
    fontWeight: 'bold',
    color: palette.main03
  }),
  'p-postRecommend__listItem__information__price': css({
    fontWeight: 'bold',
    color: palette.alert
  }),
  'p-postRecommend__listItem__cta': css(typography.textS, {
    flex: 1,
    padding: '12px 40px',
    [mq('sp')]: {
      padding: 8
    }
  }),
  /*-------------------------------------------------------
    Button
  ---------------------------------------------------------*/
  'c-btn--primary': css(btn, {
    backgroundColor: palette.main,
    boxShadow: `0 4px 0 ${palette.main02}`,
    color: palette.black
  }),
  'c-btn--secondary': css(btn, {
    border: `1px solid ${palette.main}`,
    background: palette.white,
    boxShadow: `0 4px 0 ${palette.main}`,
    color: palette.main03
  }),
  'c-btn--disable': css(btn, {
    background: palette.gray4,
    color: palette.white,
    cursor: 'default',
    marginBottom: 0
  }),
  'c-btn--cta': css(btn, {
    backgroundColor: palette.accent,
    boxShadow: `0 4px 0 ${palette.accent02}`,
    color: palette.white,
    padding: '8px 16px',
    display: 'inline-block',
    position: 'relative'
  }),
  'c-btn--cta--secondary': css(btn, {
    backgroundColor: palette.white,
    boxShadow: `0 4px 0 ${palette.accent02}`,
    border: `1px solid ${palette.accent02}`,
    color: palette.accent02,
    display: 'inline-block',
    position: 'relative'
  }),
  'c-btn--2lines': css({
    paddingTop: 2,
    paddingBottom: 2
  }),
  'c-btn--2lines__leadTxt': css({
    display: 'block',
    fontWeight: 'normal'
  }),
  'c-btn--2lines__mainTxt': css({
    lineHeight: 1.2
  }),
  /*-------------------------------------------------------
    Icon
  ---------------------------------------------------------*/
  linkArrow: css({
    position: 'relative',
    '::after': {
      fontFamily: 'Material Icons',
      content: '"\\e5cc"',
      paddingLeft: 4
    }
  }),
  externalLink: css({
    '::after': {
      fontFamily: 'Material Icons',
      content: '"\\e89e"',
      textDecoration: 'none',
      paddingLeft: '0.25rem'
    }
  }),
  /*-------------------------------------------------------
    Call To Action
  ---------------------------------------------------------*/
  'c-followingBanner--pc': css(followingBanner, {
    [mq('min', 992)]: {
      maxWidth: 740,
      marginLeft: -40
    },
    [mq('max', 992)]: {
      left: 0
    },
    [mq('max', 480)]: {
      visibility: 'hidden'
    }
  }),
  'c-followingBanner--sp': css(followingBanner, {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    left: 0,
    background: rgba(palette.black, 0.8),
    width: '100%',
    padding: '4px 8px 8px'
  }),
  'c-followingBanner__title': css({
    margin: '0 auto 4px',
    color: palette.white,
    width: '100%',
    textAlign: 'center'
  }),
  'p-post__followingCta': css({
    width: '49%',
    padding: '4px 0'
  }),
  'p-post__followingBanner': css(followingBanner, {
    [mq('min', 992)]: {
      maxWidth: 740,
      marginLeft: -40
    },
    [mq('max', 992)]: {
      left: 0
    },
    [mq('max', 640)]: {
      marginBottom: 12,
      textAlign: 'center',
      boxShadow: 'none'
    }
  }),
  'p-post__followingBanner__img': css({
    [mq('max', 640)]: {
      width: '90%',
      margin: '0 auto',
      boxShadow: elevation[1]
    }
  }),
  'p-post__followingBanner__input': css({
    display: 'none',
    ':checked': {
      '~ a img': {
        display: 'none'
      },
      '~ label': {
        display: 'none'
      }
    }
  }),
  'p-post__followingBanner__label': css({
    position: 'absolute',
    background: rgba(palette.black, 0.8),
    color: palette.white,
    borderRadius: '50%',
    width: 32,
    lineHeight: '32px',
    textAlign: 'center',
    top: '-16%',
    right: '2%',
    zIndex: 1,
    cursor: 'pointer',
    '::before': {
      fontFamily: 'Material Icons',
      content: '"\\e5cd"'
    },
    [mq('min', 640)]: {
      display: 'none'
    }
  }),
  'p-post__banner': css({
    margin: '12px 0'
  }),
  ...memorialJewerlyStyles
}
