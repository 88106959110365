import { FC, HTMLAttributes } from 'react'
import { css } from '@emotion/react'
import { palette } from '@lifedot/styles/palette'
import { TelephoneNumber } from '@lifedot/components/TelephoneNumber'
import { mq } from '@lifedot/styles/mediaQuery'

const styles = {
  tel: css({
    padding: 8,
    border: `2px solid ${palette.gray2}`,
    backgroundColor: palette.white,
    textAlign: 'center'
  }),
  text: css({
    [mq('pc')]: {
      fontSize: 14
    }
  })
}

interface TelephoneProps extends HTMLAttributes<HTMLDivElement> {
  showNote?: boolean
  requestable?: boolean
}

export const Telephone: FC<TelephoneProps> = ({
  showNote = false,
  requestable = true,
  ...rest
}) => {
  return (
    <div css={styles.tel}>
      <p>
        {requestable ? (
          <b>電話で資料請求・見学予約</b>
        ) : (
          <b css={styles.text}>希望に合ったお墓の提案をもらう</b>
        )}
      </p>
      <div {...rest}>
        <TelephoneNumber showNote={showNote} />
      </div>
    </div>
  )
}
