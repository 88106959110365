import { cemeteryMainImage } from '@lifedot/utils'
export {
  makeDescription,
  makeTitle
} from '@/components/Seo/detail/dependencies'
import { router, urlify } from '@lifedot/router'

const minPrice = (
  priceAndSpecs:
    | { price1: number | null }[]
    | ReadonlyArray<{ price1: number | null }>
): number | undefined => {
  const prices = priceAndSpecs
    .map(({ price1 }) => price1)
    .filter((price1): price1 is Exclude<typeof price1, null> => price1 !== null)
    .sort((a, b) => (a ?? Infinity) - (b ?? Infinity))
  return prices[0]
}

export const makeCemeteryJson = (cemetery: { name: string }): string => {
  return JSON.stringify({
    '@context': 'http://schema.org',
    '@type': 'Cemetery',
    name: cemetery.name
  })
}

export const makeArticleJson = (
  cemetery: {
    created_at: string
    updated_at: string
    main_image: { path: string | null } | null
  },
  path: string,
  headline: string,
  description: string
): string => {
  return JSON.stringify({
    '@context': 'http://schema.org',
    '@type': 'Article',
    datePublished: cemetery.created_at,
    headline,
    description,
    image: cemeteryMainImage(cemetery.main_image).path,
    dateModified: cemetery.updated_at,
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': urlify(path)
    }
  })
}

export const makeLocalBusinessData = (cemetery: {
  name: string
  main_image: { path: string | null } | null
  details: { postal_code: string }
  prefecture: { name: string }
  city: { name: string }
  address: string | null
  review_summary: {
    total_score: number | null
    count: number
  }
  requestable: boolean
  cemeteryId: number
  location: { lat: number; lon: number }
  price_and_specs:
    | { price1: number | null }[]
    | ReadonlyArray<{ price1: number | null }>
}): {
  geo: { '@type': string; latitude: number; longitude: number }
  image: string
  address: {
    addressCountry: string
    streetAddress: string
    '@type': string
    postalCode: string
    addressLocality: string
    addressRegion: string
  }
  '@type': string
  potentialAction:
    | {
        result: { '@type': string; name: string }
        '@type': string
        target: {
          actionPlatform: string[]
          '@type': string
          urlTemplate: string
          inLanguage: string
          url: string
        }
      }
    | undefined
  name: string
  telephone: string
  aggregateRating:
    | {
        bestRating: string
        reviewCount: number
        '@type': string
        ratingValue: number
        worstRating: string
      }
    | undefined
  '@context': string
  priceRange: 0 | undefined | string
} => {
  const min = minPrice(cemetery.price_and_specs)

  return {
    '@context': 'http://schema.org',
    '@type': 'LocalBusiness',
    name: cemetery.name,
    image: cemeteryMainImage(cemetery.main_image).path,
    telephone: 'Webサイトにてご確認ください',
    address: {
      '@type': 'PostalAddress',
      postalCode: cemetery.details.postal_code,
      addressCountry: 'JP',
      addressRegion: cemetery.prefecture.name,
      addressLocality: cemetery.city.name,
      streetAddress:
        cemetery.address
          ?.replace(new RegExp(cemetery.prefecture.name, 'g'), '')
          .replace(new RegExp(cemetery.city.name, 'g'), '') ?? ''
    },
    aggregateRating:
      cemetery.review_summary.count > 0 && cemetery.review_summary.total_score
        ? {
            '@type': 'AggregateRating',
            ratingValue:
              Math.round(cemetery.review_summary.total_score * 10) / 10,
            reviewCount: cemetery.review_summary.count,
            bestRating: '5',
            worstRating: '1'
          }
        : undefined,
    potentialAction: cemetery.requestable
      ? {
          '@type': 'ReserveAction',
          target: {
            '@type': 'EntryPoint',
            urlTemplate: urlify(
              router.requestsNew({ id: cemetery.cemeteryId, tour: true })
            ),
            url: urlify(
              router.requestsNew({ id: cemetery.cemeteryId, tour: true })
            ),
            inLanguage: 'jp',
            actionPlatform: [
              'http://schema.org/DesktopWebPlatform',
              'http://schema.org/IOSPlatform',
              'http://schema.org/AndroidPlatform'
            ]
          },
          result: {
            '@type': 'Reservation',
            name: '見学予約'
          }
        }
      : undefined,
    geo: {
      '@type': 'GeoCoordinates',
      latitude: cemetery.location.lat,
      longitude: cemetery.location.lon
    },
    priceRange: min && `${min.toLocaleString()}円〜`
  }
}

export const makeLocalBusinessJson = (
  cemetery: Parameters<typeof makeLocalBusinessData>[0]
): string => {
  return JSON.stringify(makeLocalBusinessData(cemetery))
}

export const makeServiceJson = (cemetery: {
  price_and_specs:
    | { price1: number | null }[]
    | ReadonlyArray<{ price1: number | null }>
}): string | null => {
  const min = minPrice(cemetery.price_and_specs)

  return cemetery.price_and_specs.filter(({ price1 }) => price1 !== null)
    .length === 1
    ? JSON.stringify({
        '@context': 'http://schema.org',
        '@type': 'Service',
        offers: {
          '@type': 'Offer',
          priceCurrency: 'JPY',
          price: min
        }
      })
    : null
}
