import { graphql } from 'relay-runtime'
import { useFragment } from 'react-relay/hooks'
import type { PrefectureListCemeteries_prefectureCemeteries$key } from './__generated__/PrefectureListCemeteries_prefectureCemeteries.graphql'
import type { PrefectureListCemeteries_cemetery$key } from './__generated__/PrefectureListCemeteries_cemetery.graphql'
import { Cemeteries } from './Cemeteries'
import { useListCemeteries } from './dependencies'

type PrefectureListCemeteriesProps = {
  cemetery: PrefectureListCemeteries_cemetery$key
  prefectureCemeteries: PrefectureListCemeteries_prefectureCemeteries$key
}

export const PrefectureListCemeteries: React.FC<
  PrefectureListCemeteriesProps
> = ({ prefectureCemeteries, cemetery }) => {
  const {
    cemeteryId,
    prefecture: { name: prefectureName }
  } = useFragment(cemeteryFragment, cemetery)

  const { items } = useFragment(listFragment, prefectureCemeteries)
  const cemeteryItems = useListCemeteries({ cemeteryId, items })

  if (cemeteryItems.length < 1) return null
  return (
    <Cemeteries
      eventLabel="cemeteryCard_都道府県で人気の霊園"
      title={`${prefectureName}で人気の霊園`}
      cemeteryItems={cemeteryItems}
    />
  )
}

const listFragment = graphql`
  fragment PrefectureListCemeteries_prefectureCemeteries on CemeteryResult {
    items {
      cemeteryId: id
      ...useCemeteryTinyItem_cemetery
    }
  }
`

const cemeteryFragment = graphql`
  fragment PrefectureListCemeteries_cemetery on Cemetery {
    cemeteryId: id
    prefecture {
      name
    }
  }
`
