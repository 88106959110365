import { FC, ReactNode } from 'react'
import { css } from '@emotion/react'
import { palette } from '@lifedot/styles/palette'

const styles = {
  label: css({
    position: 'absolute',
    left: 0,
    top: 0,
    color: palette.white,
    backgroundColor: palette.accent02,
    writingMode: 'vertical-rl',
    height: '100%',
    textAlign: 'center',
    fontSize: 14,
    fontWeight: 'bold',
    letterSpacing: 4,
    borderRadius: '3px 0 0 3px'
  })
}

type ButtonLabelProps = {
  children: ReactNode
}
export const ButtonLabel: FC<ButtonLabelProps> = ({ children }) => {
  return <span css={styles.label}>{children}</span>
}
