import { AnchorHTMLAttributes, FC } from 'react'
import { css } from '@emotion/react'
import { Button } from '@lifedot/atoms/Button'
import { palette } from '@lifedot/styles/palette'
import { typography } from '@lifedot/styles/typography'
import Link from 'next/link'
import { router } from '@lifedot/router'
import { ButtonLabel } from '@lifedot/components/ButtonLabel'

const styles = {
  balloon: css({
    position: 'absolute',
    top: -20,
    left: 0,
    backgroundColor: palette.main,
    padding: '2px 8px',
    borderRadius: 4,
    display: 'inline-block',
    fontWeight: 'bold',
    '&::before': {
      content: '""',
      position: 'absolute',
      bottom: -10,
      left: 32,
      borderBottom: '10px solid transparent',
      borderRight: `10px solid ${palette.main}`
    }
  }),
  anchor: css({
    position: 'relative',
    textDecoration: 'none',
    width: '100%',
    display: 'block'
  })
}

interface TourButtonProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  cemeteryId: number
}

export const TourButton: FC<TourButtonProps> = ({ cemeteryId, ...props }) => {
  return (
    <Link
      href={router.requestsNew({ id: cemeteryId, tour: true })}
      passHref
      prefetch={false}
    >
      <a css={styles.anchor} rel="nofollow" {...props}>
        <Button
          mainText={
            <>
              <span css={typography.textL}>見学予約する</span>
              <ButtonLabel>無料</ButtonLabel>
            </>
          }
          color="accent"
          variant="secondary"
          as="div"
        />
        <span css={[typography.textXS, styles.balloon]}>
          人気の区画は、すぐに空きが無くなります
        </span>
      </a>
    </Link>
  )
}
