import {
  PrefectureRomas,
  urbanPrefectures
} from '@lifedot/constants/prefectures'
import { useMemo } from 'react'

export const useIsUrban = (prefRoma: PrefectureRomas) =>
  useMemo(() => {
    return urbanPrefectures.includes(prefRoma)
  }, [prefRoma])
