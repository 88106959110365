/**
 * @generated SignedSource<<86dc4f5b5e0f1aa1145d9af1c592753e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime'
import { FragmentRefs } from 'relay-runtime'
export type StickyTelephoneBanner_cemetery$data = {
  readonly requestable: boolean
  readonly prefecture: {
    readonly roma: string
  }
  readonly ' $fragmentType': 'StickyTelephoneBanner_cemetery'
}
export type StickyTelephoneBanner_cemetery$key = {
  readonly ' $data'?: StickyTelephoneBanner_cemetery$data
  readonly ' $fragmentSpreads': FragmentRefs<'StickyTelephoneBanner_cemetery'>
}

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: null,
  name: 'StickyTelephoneBanner_cemetery',
  selections: [
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'requestable',
      storageKey: null
    },
    {
      alias: null,
      args: null,
      concreteType: 'CemeteryPrefecture',
      kind: 'LinkedField',
      name: 'prefecture',
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'roma',
          storageKey: null
        }
      ],
      storageKey: null
    }
  ],
  type: 'Cemetery',
  abstractKey: null
}

;(node as any).hash = 'a14ae747f4ba90fc87b746328e698fe9'

export default node
