import { css, SerializedStyles } from '@emotion/react'
import { mq } from '@lifedot/styles/mediaQuery'
import { typography } from '@lifedot/styles/typography'

const headline = css({
  fontFamily:
    'YuMincho, Yu Mincho, 游明朝体, ヒラギノ明朝 ProN, Hiragino Mincho ProN, serif',
  fontWeight: 'normal'
})

/*-------------------------------------------------------
  TOMONiメモリアルジュエリープロモーションページ用スタイル
---------------------------------------------------------*/
export const memorialJewerlyStyles: Record<string, SerializedStyles> = {
  'p-memorialJewerly__headerCopy': css(typography.headingM, headline, {
    marginBottom: '1em',
    textAlign: 'center'
  }),
  'p-memorialJewerly__headline': css(typography.headingM, headline, {
    display: 'flex',
    alignItems: 'center',
    margin: '2em 0 1em',
    textAlign: 'center',
    '::before, ::after': {
      borderTop: '1px solid #68c3c4',
      content: '""',
      flexGrow: 1
    },
    '::before': {
      marginRight: '1em'
    },
    '::after': {
      marginLeft: '1em'
    }
  }),
  'p-memorialJewerly__subheader': css(typography.headingS, headline, {
    position: 'relative',
    margin: '2em 0 1.6em',
    textAlign: 'center',
    '::after': {
      position: 'absolute',
      content: '""',
      width: 72,
      borderTop: '1px solid #68c3c4',
      left: '50%',
      bottom: -12,
      transform: 'translateX(-50%)'
    }
  }),
  'p-memorialJewerly__subtitle': css(typography.textXL, headline, {
    margin: '1.6em 0 1em',
    textAlign: 'center'
  }),
  'p-memorialJewerly__ctaBox': css({
    backgroundColor: 'rgba(104, 195, 196, .1)',
    marginTop: '1.6em',
    padding: '24px 32px 28px',
    [mq('sp')]: {
      paddingLeft: 16,
      paddingRight: 16
    }
  }),
  'p-memorialJewerly__ctaBox__title': css(typography.textXL, headline, {
    textAlign: 'center'
  }),
  'p-memorialJewerly__ctaBox__wrap': css({
    display: 'flex',
    [mq('sp')]: {
      flexDirection: 'column'
    }
  }),
  'p-memorialJewerly__ctaBox__image': css({
    height: 132,
    [mq('sp')]: {
      display: 'block',
      width: '80%',
      height: 'auto',
      margin: 'auto'
    }
  }),
  'p-memorialJewerly__ctaBox__description': css({
    marginLeft: 24,
    [mq('sp')]: {
      marginTop: 8,
      marginLeft: 0
    }
  }),
  'p-memorialJewerly__ctaBox__button': css({
    width: '100%'
  }),
  'p-memorialJewerly__pageLink': css({
    display: 'flex',
    justifyContent: 'space-between',
    '> a': {
      width: '32%'
    }
  }),
  'p-memorialJewerly__featureBox': css({
    display: 'flex',
    [mq('sp')]: {
      flexDirection: 'column'
    }
  }),
  'p-memorialJewerly__featureBox__image': css({
    height: 142,
    marginRight: 16,
    [mq('sp')]: {
      height: 'auto',
      marginRight: 0,
      marginBottom: '1em'
    }
  }),
  'p-memorialJewerly__reviewBox': css({
    display: 'flex',
    padding: 24,
    border: '1px solid #d0dae0',
    [mq('sp')]: {
      flexDirection: 'column'
    },
    ':not(:first-of-type)': {
      marginTop: '1em'
    }
  }),
  'p-memorialJewerly__reviewBox__image': css({
    height: 246,
    [mq('sp')]: {
      height: 'auto'
    }
  }),
  'p-memorialJewerly__reviewBox__title': css(typography.textXL, headline, {
    marginBottom: '1em'
  }),
  'p-memorialJewerly__reviewBox__description': css({
    marginLeft: 16,
    [mq('sp')]: {
      marginLeft: 0,
      marginTop: '1em'
    }
  })
}
