import { DetailSeoHead_cemetery$data } from '@/components/Seo/detail/__generated__/DetailSeoHead_cemetery.graphql'

const alternateImage = {
  path: 'https://s3.lifedot.jp/uploads/ending/path/68749/ohaka.jpg',
  alt: 'no image'
}

export const makeMetaImage = (
  cemetery: Pick<DetailSeoHead_cemetery$data, 'main_image'>
): { path: string } => {
  return {
    path: cemetery.main_image?.path ?? alternateImage.path
  }
}
