import { Breadcrumb_cemetery$data } from './__generated__/Breadcrumb_cemetery.graphql'
import { listPath, router } from '@lifedot/router'
import { useRouter } from 'next/router'

type LinkData = Array<{
  href: string
  name: string
}>

export const useBreadcrumbItemData = ({
  cemeteryData
}: {
  cemeteryData: Breadcrumb_cemetery$data | null
}): LinkData => {
  const { pathname } = useRouter()
  if (!cemeteryData) return []

  return [
    { href: '/', name: '【お墓・霊園・墓地探し】ライフドット' },
    {
      href: listPath({ prefectureRoma: cemeteryData.prefecture.roma }),
      name: `${cemeteryData.prefecture.name}のおすすめお墓一覧ランキング（霊園・墓地）`
    },
    cemeteryData.designated_city?.name &&
    cemeteryData.designated_city?.designatedCityId
      ? {
          href: router.list({
            prefectureRoma: cemeteryData.prefecture.roma,
            cityId: cemeteryData.designated_city.designatedCityId
          }),
          name: `${cemeteryData.designated_city.name}のお墓（霊園・墓地）`
        }
      : null,
    cemeteryData.city
      ? {
          href: router.list({
            prefectureRoma: cemeteryData.prefecture.roma,
            cityId: cemeteryData.city.cityId
          }),
          name: `${cemeteryData.city.name}のお墓（霊園・墓地）`
        }
      : null,
    cemeteryData.nearest_station.name && cemeteryData.nearest_station.stationId
      ? {
          href: router.list({
            prefectureRoma: cemeteryData.prefecture.roma,
            stationId: cemeteryData.nearest_station.stationId
          }),
          name: `${cemeteryData.nearest_station.name}駅のお墓（霊園・墓地）`
        }
      : null,
    {
      href: router.cemetery(cemeteryData.cemeteryId),
      name: cemeteryData.name
    },
    pathname && pathname.includes('/review')
      ? {
          href: router.review(cemeteryData.cemeteryId, 2),
          name: `${cemeteryData.name}の口コミ・評判`
        }
      : null,
    pathname && pathname.includes('/price')
      ? {
          href: router.price(cemeteryData.cemeteryId),
          name: `${cemeteryData.name}の費用・価格`
        }
      : null
  ].filter((item): item is Exclude<typeof item, null> => item !== null)
}
