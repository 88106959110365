import { FC } from 'react'
import { css } from '@emotion/react'
import { EventTracker } from '@lifedot/tracking'
import { lifedotChallengerNumber } from '@lifedot/constants/phoneNumbers'
import { ResponsiveBanner } from '@lifedot/components/ResponsiveBanner'
import { palette } from '@lifedot/styles/palette'
import { rgba } from 'emotion-rgba'

const styles = {
  wrap: css({
    backgroundColor: rgba(palette.black, 0.9),
    padding: '16px 0',
    display: 'flex',
    justifyContent: 'center'
  })
}

export const TelephoneBanner: FC = () => {
  return (
    <div css={styles.wrap}>
      <EventTracker
        category="anchor"
        label="follow_call"
        action="click"
        elementCategory="anchor"
        elementLabel="follow_call"
      >
        <ResponsiveBanner
          banners={[
            {
              link: `tel:${lifedotChallengerNumber}`,
              alt: `資料請求・見学予約を電話で承ります：${lifedotChallengerNumber}`,
              desktopSrc:
                'https://s3.lifedot.jp/uploads/ending/path/84588/Telephone_pc.png',
              mobileSrc:
                'https://s3.lifedot.jp/uploads/ending/path/84587/Telephone.png'
            }
          ]}
          pcSize={{ width: 742, height: 76 }}
          spSize={{ width: 343, height: 80 }}
        />
      </EventTracker>
    </div>
  )
}
