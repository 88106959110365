import { DOMNode, domToReact, HTMLReactParserOptions } from 'html-react-parser'
import { css } from '@emotion/react'
import { replaceableStyles } from './styles'
import { isElement } from '@lifedot/utils'

export const makeReplace =
  (replace?: HTMLReactParserOptions['replace']) =>
  // eslint-disable-next-line react/display-name
  (
    domNode: DOMNode
  ): ReturnType<Required<HTMLReactParserOptions>['replace']> => {
    const replaced = replace?.(domNode)
    if (replaced) return replaced

    if (!isElement(domNode)) return

    // 直書きされているスタイルを消す
    // eslint-disable-next-line react/jsx-no-useless-fragment
    if (domNode.name === 'style') return <></>

    // スタイルを置換
    const {
      style,
      class: className,
      for: htmlFor,
      rowspan: rowSpan,
      colspan: colSpan,
      ...restAttributes
    } = domNode.attribs

    const css = newCss(style, className)

    return (
      <>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <domNode.name
          {...restAttributes}
          {...(css ? { css } : {})}
          htmlFor={htmlFor}
          rowSpan={rowSpan}
          colSpan={colSpan}
          loading={domNode.name === 'img' ? 'lazy' : undefined}
          className={className}
        >
          {domNode.children.length > 0
            ? domToReact(domNode.children, { replace: makeReplace(replace) })
            : undefined}
        </domNode.name>
      </>
    )
  }

const newCss = (style: string | undefined, className: string | undefined) => {
  if (!style && !className) return undefined
  return [
    css(style),
    (className?.split(/\s/) ?? []).map((c) => css(replaceableStyles[c]))
  ]
}
