import { FC } from 'react'
import { css } from '@emotion/react'
import { Banners as LibsBanners } from '@lifedot/components/Banners'
import { mq } from '@lifedot/styles/mediaQuery'

const styles = {
  wrap: css({
    [mq('sp')]: {
      margin: '0 8px'
    }
  })
}

export const Banners: FC = () => {
  return (
    <div css={styles.wrap}>
      <LibsBanners shows={['client']} />
    </div>
  )
}
