import { orderedSectionType } from '@lifedot/utils'
import { DetailSeoHead_cemetery$data } from './__generated__/DetailSeoHead_cemetery.graphql'

export const makeItems = (
  cemetery: DetailSeoHead_cemetery$data
): {
  title: string
  description: string
  keywords: string[]
} => {
  const title = makeTitle(cemetery)
  const description = makeDescription(cemetery)
  const keywords = ['', '価格', '地図', '口コミ'].map((keyword) =>
    keyword !== '' ? `${cemetery.name} ${keyword}` : cemetery.name
  )

  return {
    title,
    description,
    keywords
  }
}

export const makeTitle = (cemetery: {
  name: string
  city: { name: string }
  price_and_specs: unknown[] | ReadonlyArray<unknown>
}): string => {
  return `${cemetery.name}(${cemetery.city.name})の${
    cemetery.price_and_specs.length > 0 ? '費用・' : ''
  }交通アクセス・お墓の詳細情報 | 無料で資料請求【ライフドット公式】`
}

export const makeDescription = (cemetery: {
  name: string
  prefecture: { name: string }
  city: { name: string }
  nearest_station: { name: string | null }
  choices:
    | Array<{ name: string; code: string }>
    | ReadonlyArray<{ name: string; code: string }>
}): string => {
  const sectionTypeNames = orderedSectionType([...cemetery.choices])
    .map(({ name }) => name)
    .join('・')
  return `${cemetery.name}(${cemetery.prefecture.name}${cemetery.city.name}${
    cemetery.nearest_station.name ? `／${cemetery.nearest_station.name}駅` : ''
  })の${sectionTypeNames}に関する施設詳細を無料で紹介！お墓探しのライフドットでは、墓石のお墓、永代供養墓、区画タイプ別の特徴、費用・価格、口コミ、現地レポート、地図とアクセス方法、駐車場情報を紹介しています。建墓のポイントや石材店の選び方も詳細に解説。霊園・墓地の比較が簡単にでき、資料請求や見学予約が無料でできます。`
}
